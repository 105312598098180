import React from "react";
import Input from "./input";
import { useProductListingContext } from "../context/productListingContext";
import Select from "./select";
import TextArea from "./textArea";
import Button from "./button";

export const RenderInput = (name, label, type) => {
  const {
    handleChange,
    lifespan,
    ingredients,
    nafdacNo,
    functionalName,
    genericName,
    tradeItemDescription,
    strength,
    activeIngredients,
    productForm,
    atcCode,
    routeOfAdministration,
    shelfLife,
    globalProductCategory,
    packSize,
    quantity,
    directionOfUse,
    primaryPackaginMaterial,
    productdescription,
    netweight,
  } = useProductListingContext();
  const data = {
    netweight,
    lifespan,
    ingredients,
    nafdacNo,
    functionalName,
    genericName,
    tradeItemDescription,
    strength,
    activeIngredients,
    productForm,
    atcCode,
    routeOfAdministration,
    shelfLife,
    globalProductCategory,
    packSize,
    quantity,
    directionOfUse,
    primaryPackaginMaterial,
    productdescription,
  };

  return (
    <Input
      name={name}
      label={label}
      onChange={(e) => handleChange(e, name)}
      defaultValue={data[name]}
      type={type}
    />
  );
};

export const RenderSelect = (name, label, options) => {
  const { netcontent_id, handleChange, lifespanunit, packunit } =
    useProductListingContext();
  const data = { netcontent_id, lifespanunit, packunit };

  return (
    <Select
      key={options.id}
      name={name}
      label={label}
      options={options}
      onChange={(e) => handleChange(e, name)}
      defaultValue={data[name]}
    />
  );
};

export const RenderTextArea = (name, label) => {
  const {
    ingredients,
    handleChange,
    storage,
    directionofuse,
    marketingmessage,
  } = useProductListingContext();
  const data = { ingredients, storage, directionofuse, marketingmessage };

  return (
    <TextArea
      name={name}
      label={label}
      defaultValue={data[name]}
      onChange={(e) => handleChange(e, name)}
    />
  );
};

export const RenderButton = (clickAction, style, name) => {
  const { nextPage, previousPage } = useProductListingContext();
  const data = { nextPage, previousPage };
  return <Button name={name} onClick={clickAction} className={style} />;
};

// export default Form;
