import {
  CContainer,
  CRow,
  CCol,
  CForm,
  CButton,
  CFormInput,
  CFormLabel,
  CFormCheck,
  CFormSelect,
  CFormSwitch,
  CSpinner,
  CTooltip,
} from "@coreui/react";
import { AiFillInfoCircle } from "react-icons/ai";
import Select from "react-select";
// import { CDatePicker } from "@coreui/react-pro@next";
import React, { useState, useEffect } from "react";

import { useGLNContext } from "../../context/glnContext";

// import { options } from "joi-browser";
// import { set } from "lodash";
// import { forEach } from "lodash";

const PhysicalGLN = () => {

  const {
    openningDate,
    handleChange,
    handleCountrySelection,
    handleStateSelection,
    longitude,
    functionName,
    latitude,
    functionDescription,
    IPAddress,
    previousPage,
    affiliation,
    affiliationTo,
    dispatch,
    URL,
    PortNumber,
    PhoneNumber,
    Email,
    countries,
    selectedCountry,
    selectedState,
    states,
    Street,
    City,
    State,
    LandMark,
    NearestBusStop,
    setCountryFocus,
    setStateFocus,
    setCityFocus,
    setStreetFocus,
    PostalCode,
    fetchingCoord

  } = useGLNContext();
  // const selectedCoutry = {
  //   name: "",
  //   id: 0
  // }
  // const [country, setCountry] = useState(selectedCoutry);



  const preview = () => {

    if (
      openningDate == "" ||
      longitude == "" ||
      latitude == "" ||
      City == "" ||
      Street == ""
    ) {
      return (
        <CButton
          color="primary"
          style={{ marginLeft: "5px" }}
          shape="rounded-pill"
          disabled
        >
          Please fill in all the fields
        </CButton>
      );
    } else {
      return (
        <CButton
          color="primary"
          style={{ marginLeft: "5px" }}
          shape="rounded-pill"
          onClick={() => dispatch({ type: "FINAL_STAGE", payload: 8 })}
        >
          Review <i className="fa fa-arrow-right " />
        </CButton>
      );
    }
  };
  const countryoption = countries.map((item) => {
    const container = {};

    container.value = item.id;
    container.label = item.name;

    return container;
  });

  let isarr = Array.isArray(states);
  const stateoption = isarr ? states.map((item) => {
    const container = {};

    container.value = item.id;
    container.label = item.name;

    return container;
  }) : null;


  const renderCountry = () => {

    return (
      <Select
        onFocus={(e) => setCountryFocus(true)}
        onBlur={(e) => setCountryFocus(false)}
        className="dropdown"
        placeholder="select country"
        value={selectedCountry}
        options={countryoption}
        onChange={(e) => handleCountrySelection(e)} // assign onChange function

        isDisabled={Array.isArray(countries) ? false : true}
        isClearable
        name="country"
      />
    )
  }
  const renderState = () => {

    return (
      <Select
        onFocus={(e) => setStateFocus(true)}
        onBlur={(e) => setStateFocus(false)}
        className="dropdown"
        placeholder="select state"
        value={selectedState}
        options={stateoption}
        onChange={(e) => handleStateSelection(e)} // assign onChange function

        isClearable
        name="state"
      />
    )
  }





  return (
    <>
      <div className="d-flex flex-row justify-content-between">
        <div>
          <h3>Physical GLN Location</h3>
          <p style={{ color: "red" }}>*please note that all field are mandetory*</p>
        </div>
        <div className="d-flex align-content-center flex-wrap">
         {fetchingCoord &&  <CSpinner />}
        </div>
      </div>
      <hr />
      <CForm
        className="row g-3"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {/* country */}
        <CCol md={4}>

          <CFormLabel>Select Country:
          </CFormLabel>


          {renderCountry()}
        </CCol>
        {/* state */}
        <CCol md={4}>

          <CFormLabel>Select State:
          </CFormLabel>


          {renderState()}
        </CCol>

        {/* city */}
        <CCol md={4}>
          <div className="d-flex justify-content-between">
            <CFormLabel htmlFor="">City</CFormLabel>
            <CTooltip
              trigger={"hover"}
              placement="top"
              content="The name of a city the location is situated"
            >
              <span>
                <AiFillInfoCircle color="pupple" size="20" />
              </span>
            </CTooltip>
          </div>
          <CFormInput
            onFocus={(e) => setCityFocus(true)}
            onBlur={(e) => setCityFocus(false)}
            type="text"
            name=""
            id=""
            defaultValue={City}
            onChange={(e) => handleChange(e, "City")}
          />
        </CCol>
        {/* bus stop */}
        <CCol md={4}>
          <div className="d-flex justify-content-between">
            <CFormLabel htmlFor="">Nearest Bustop</CFormLabel>
            <CTooltip
              trigger={"hover"}
              placement="top"
              content="The nearest bustop to the location."
            >
              <span>
                <AiFillInfoCircle color="pupple" size="20" />
              </span>
            </CTooltip>
          </div>
          <CFormInput
            type="text"
            name=""
            id=""
            defaultValue={NearestBusStop}
            onChange={(e) => handleChange(e, "NearestBusStop")}
          />
        </CCol>

        {/* LandMark */}
        <CCol md={4}>
          <div className="d-flex justify-content-between">
            <CFormLabel htmlFor="">Landmark</CFormLabel>
            <CTooltip
              trigger={"hover"}
              placement="top"
              content="A structure of unusual historical and usually aesthetic interest near the location"
            >
              <span>
                <AiFillInfoCircle color="pupple" size="20" />
              </span>
            </CTooltip>
          </div>
          <CFormInput
            type="text"
            name=""
            id=""
            defaultValue={LandMark}
            onChange={(e) => handleChange(e, "LandMark")}
          />
        </CCol>
        {/* street */}
        <CCol md={4}>
          <div className="d-flex justify-content-between">
            <CFormLabel htmlFor="">Street</CFormLabel>
            <CTooltip
              trigger={"hover"}
              placement="top"
              content="Street should start with building number."
            >
              <span>
                <AiFillInfoCircle color="pupple" size="20" />
              </span>
            </CTooltip>
          </div>
          <CFormInput
            type="text"
            onFocus={(e) => setStreetFocus(true)}
            onBlur={(e) => setStreetFocus(false)}
            name=""
            id=""
            defaultValue={Street}
            onChange={(e) => handleChange(e, "Street")}
          />
        </CCol>

        <CCol md={4}>
          <div className="d-flex justify-content-between">
            <CFormLabel>Opening Days:
            </CFormLabel>
            <CTooltip
              trigger={"hover"}
              placement="top"
              content="The days of the week that this location open for operation. e.g Monday-Friday"
            >
              <span>
                <AiFillInfoCircle color="pupple" size="20" />
              </span>
            </CTooltip>
          </div>
          <CFormInput
            name="openningDate"
            defaultValue={openningDate}
            type="text"
            onChange={(e) => handleChange(e, "openningDate")}
          />
        </CCol>



        <CCol md={4}>
          <div className="d-flex justify-content-between">
            <CFormLabel>Affiliation:</CFormLabel>
            <CTooltip
              trigger={"hover"}
              placement="top"
              content="Corporate
                  Social
                  Responsibility
                  Affiliation. A URI value (website address) linking to
                  information related to social
                  responsibility activities and
                  associations that a location is
                  involved with."
            >
              <span>
                <AiFillInfoCircle color="pupple" size="20" />
              </span>
            </CTooltip>
          </div>
          <CFormInput
            name=""
            defaultValue={affiliation}
            type="text"
            onChange={(e) => handleChange(e, "affiliation")}
          />
        </CCol>

        {/* postalcode */}
        <CCol md={4}>
          <div className="d-flex justify-content-between">
            <CFormLabel htmlFor="">Postal Code</CFormLabel>
            <CTooltip
              trigger={"hover"}
              placement="top"
              content="The Postal Code for the location"
            >
              <span>
                <AiFillInfoCircle color="pupple" size="20" />
              </span>
            </CTooltip>
          </div>
          <CFormInput
            type="text"
            name=""
            id=""
            defaultValue={PostalCode}
            onChange={(e) => handleChange(e, "PostalCode")}
          />
        </CCol>

        <CCol md={4}>
          <div className="d-flex justify-content-between">
            <CFormLabel htmlFor="">Longitude</CFormLabel>
            <CTooltip
              trigger={"hover"}
              placement="top"
              content="Geographic coordinate that specifies the east–west position of a point on the surface of the Earth. It can quicky be gotten by searching the location address in google map"
            >
              <span>
                <AiFillInfoCircle color="pupple" size="20" />
              </span>
            </CTooltip>
          </div>
          <CFormInput
            type="text"
            name="longitude"
            id=""
            defaultValue={longitude}
            onChange={(e) => handleChange(e, "longitude")}
          />
        </CCol>

        <CCol md={4}>
          <div className="d-flex justify-content-between">
            <CFormLabel htmlFor="">Latitude</CFormLabel>
            <CTooltip
              trigger={"hover"}
              placement="top"
              content="Latitude is a measurement of a location north or south of the Equator.It can quicky be gotten by searching the location address in google map"
            >
              <span>
                <AiFillInfoCircle color="pupple" size="20" />
              </span>
            </CTooltip>
          </div>
          <CFormInput
            type="text"
            name="longitude"
            id=""
            defaultValue={latitude}
            onChange={(e) => handleChange(e, "latitude")}
          />
        </CCol>



        {/* <CCol md={4}>
          <div className="d-flex justify-content-between">
            <CFormLabel htmlFor="">State</CFormLabel>
            <CTooltip
              trigger={"hover"}
              placement="top"
              content="The name of the state the location is situated"
            >
              <span>
                <AiFillInfoCircle color="pupple" size="20" />
              </span>
            </CTooltip>
          </div>
          <CFormInput
            type="text"
            name=""
            id=""
            defaultValue={State}
            onChange={(e) => handleChange(e, "State")}
          />
        </CCol> */}




        <CCol xs={12}>
          <CButton
            style={{ marginLeft: 10 }}
            shape="rounded-pill"
            onClick={() => dispatch({ type: "PREVIOUS_FORM", payload: 0 })}
          >
            <i className=" fa fa-arrow-left " /> previous page
          </CButton>
          {preview()}
        </CCol>


      </CForm>
    </>
  );
}

export default PhysicalGLN;
