import React, { useEffect, useState } from "react";

import {
  CCallout,
  CRow,
  CCol,
  CContainer,
  CFormCheck,
  CButton,
  CSpinner,
} from "@coreui/react";
import sub from "./glnimages/sub.png";
import cloud from "./glnimages/cloud.png";
import ship from "./glnimages/ship.png";
import custom from "./glnimages/custom.png";

import { useGLNContext } from "../../context/glnContext";

const GlnTypes = () => {
  const packaginglevel_id = 1;
  const { handleSelection, selectedgln, handlenext, level } = useGLNContext();
  //   console.log(selectedgln, "SGHSJH");

  // console.log("market value", market);
  console.log(level, "level");
  const rendercard = () => {
    if (selectedgln === 1) {
      return (
        <CCallout color="primary">
          An organizational subdivision or department.{" "}
        </CCallout>
      );
    } else if (selectedgln === 2) {
      return (
        <CCallout color="secondary">
          A tangible place that is expected to change locations and maybe be
          represented by an address, coordinates or other means.{" "}
        </CCallout>
      );
    } else if (selectedgln === 3) {
      return (
        <CCallout color="success">
          An electronic(Non Physical) address that is used for communication
          between computer systems.{" "}
        </CCallout>
      );
    } else if (selectedgln === 4) {
      return (
        <CCallout color="warning">
          A tangible place that does not change locations and may be represented
          by an address, coordinates, or other means.{" "}
        </CCallout>
      );
    }
  };
  const showThisImage = () => {
    if (selectedgln === 1) {
      return (
        <img className="gtin-icons" src={sub} style={{ height: 200 }} alt="" />
      );
    } else if (selectedgln === 2) {
      return (
        <img
          className="gtin-icons"
          src={ship}
          style={{ height: 200, width: 300 }}
          alt=""
        />
      );
    } else if (selectedgln === 3) {
      return (
        <img
          className="gtin-icons"
          src={cloud}
          style={{ height: 200 }}
          alt=""
        />
      );
    } else if (selectedgln === 4) {
      return (
        <img
          className="gtin-icons"
          src={custom}
          style={{ height: 200 }}
          alt=""
        />
      );
    }
  };
  return (
    <div>
      <CContainer>
        <h3> Select GLN type </h3>{" "}
        {/* {loading && <CSpinner color="warning" variant="grow" />} */} <hr />
        {/* <p> Top level explanation of packaging level </p>  */}
        {rendercard()}{" "}
        <CRow>
          <CCol sm={8}>
          <CFormCheck
          className="mt-3"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              label="Physical Location GLN"
              onChange={() => handleSelection(4)}
            />{" "}
            <CFormCheck
            className="mt-3"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              label="Functional Location GLN"
              onChange={() => handleSelection(1)}
            />{" "}
            <CFormCheck
            className="mt-3"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              label="Mobile Location GLN"
              onChange={() => handleSelection(2)}
            />{" "}
            <CFormCheck
            className="mt-3"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              label="Digital Location GLN"
              onChange={() => handleSelection(3)}
            />{" "}
           
            {/* {loadPackaging.map((p) => {
                                          return (
                                            <CFormCheck
                                              key={p.id}
                                              type="radio"
                                              name="flexRadioDefault"
                                              label={p.name}
                                              // onChange={() => handlePackaging(p.id)}
                                            />
                                          );
                                        })} */}{" "}
          </CCol>{" "}
          <CCol sm={4}> {showThisImage()} </CCol>{" "}
        </CRow>{" "}
        {selectedgln > 0 ? (
          <button
            className="h-10 text-white bg-gs1-blue w-36"
            onClick={handlenext}
          >
            Next <i className="fa fa-arrow-right " />
          </button>
        ) : (
          <p style={{ color: "red" }}> "please select a GLN type" </p>
        )}{" "}
      </CContainer>{" "}
    </div>
  );
};

export default GlnTypes;
