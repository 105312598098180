import http, { fetchJwt } from "./http";
import axios from "axios";
const apiUrl = "packagelevel"
const apiUrla = "packagingtype"
const apiUrlb = "targetmarket"
const apiUrlc = "brandinformation/getbyregistrationid/"
const apiUrld = "brandinformation/createbrandinformation"
const apiUrle = "netcontent"
const apiUrlf = "brickcategory"
const apiUrlh = "product/getbyregistrationid/"
const apiUrli = "dashboard/"
const apiUrlMP = "member/profile/"
const apiUrlj = "gtinfee"
const apiUrlk = "product/uploadwithgtin"
const apiUrll = "gtinrequest/additional"
const apiUrlm = "product/uploadwithoutgtin"
const apiUrln = "imagebank/generatebarcode"
const apiUrlx = "glndocumentdownload"
const apiUrlo = "imagerequest/gtin/create"
const apiUrlp = "glninformation/getbyregistrationid"
const apiUrlq = "targetmarket"
const apiGetITFs="itf14/itf14s"
const apiUrlcountries="country/getall"
const apiUrlstate="state/getbycountry"


export function getPackagingLevel() {
    return http.get(apiUrl)
}

export function getTargetMarket() {
    return http.get(apiUrlq)
}


export function getBrickCategory() {
    return http.get(apiUrlf)
}

export function getPackagingType() {
    return http.get(apiUrla)
}

export function getTargetmarket() {
    return http.get(apiUrlb)
}

export function getBrandDetails(id) {
    return http.get(apiUrlc + id)

}
export function getunitOfMeasure() {
    return http.get(apiUrle)
}
export function listedProducts(regID) {
    return http.get(apiUrlh + regID)
}
export function saveBrand(brand) {
    return http.post(apiUrld, brand)
}
export function getDashBoardInfo(id) {
    return http.get(apiUrli + id)
}
export function getExtraDashBoardInfo() {
    return http.get(apiUrlMP)
}
export function getGTinFeedetails() {
    return http.get(apiUrlj)
}
export function uploadExcelWithGTin(data) {
    return http.post(apiUrlk, data)
}
export function uploadExcelWithoutGTin(data) {
    return http.post(apiUrlm, data)
}
export function additionalRequest(data) {
    return http.post(apiUrll, data)
}
export function imageRequest(data) {
    return http.post(apiUrlo, data)
}
export function getGLnRecords(id) {
    return http.get(`${apiUrlp}?registrationID=${id}`)
}
export function getStates(id) {
    return http.get(`${apiUrlstate}?country_id=${id}`)
}
export function getCountries() {
    return http.get(`${apiUrlcountries}`)
}
export function getITF14s(regid) {
    return http.get(`${apiGetITFs}?registrationID=${regid}`)
}
export function getCurrentUser() {
    try {
        const getUser = sessionStorage.getItem("company")
        return getUser
    } catch (ex) {
        return null
    }

}
export function generateBarcode(data) {
    return http.post(apiUrln, data)

}

export function generateGLNDocument(data) {
    // return http.get(apiUrlx, data)
    return http.get(`${apiUrlx}?gln=${data}`)

}

// export function getMovieWithID(movieID) {
//     return http.get(apiUrl + '/' + movieID)
// }

// export function deleteMovies(movieID) {
//     return http.delete(apiUrl + '/' + movieID)
// }
// export function saveMovie(movie) {
//     if (movie._id) {
//         const body = {...movie }
//         delete body._id
//         return http.put(apiUrl + '/' + movie._id, body)
//     }
//     return http.post(apiUrl, movie)
// }