import { CContainer } from "@coreui/react";
import { React, useState, useEffect } from "react";
import { CRow, CCol, CForm, CButton, CSpinner } from "@coreui/react";
import { targetMarket } from "./../../fakedata/market";
// import { useProductListingContext } from "../../context/productListingContext";
import { useGLNContext } from "../../context/glnContext";
// import { useEffect } from "react";
import { saveProduct } from "../../services/generalService";
import { toast } from "react-toastify";
import { fromPairs } from "lodash";
import axios from "axios";
import { useUserContext } from "../../context/userContext";
const ShowGLNDetails = () => {
  // const [targetMarkets, setTargetMarket] = useState([]);
  const {
    dispatch,
    previousPage,
    glnType,
    level,
    selectedgln,
    affiliation,
    affiliationTo,
    isOnwedBy,
    openningDate,
    latitude,
    longitude,
    functionName,
    functionDescription,
    IPAddress,
    PortNumber,
    URL,
    PhoneNumber,
    Email,
    Street,
    City,
    State,
    LandMark,
    NearestBusStop,
    PostalCode,
    AddressName,
  } = useGLNContext();
  const { companyname, registrationid } = useUserContext();
  // console.log("Company name", companyname);

  const [glnTypeId, setGlnTypeID] = useState();
  useEffect(() => {
    if (selectedgln == 1) {
      setGlnTypeID(2);
    } else if (selectedgln == 2) {
      setGlnTypeID(4);
    } else if (selectedgln == 3) {
      setGlnTypeID(5);
    } else if (selectedgln == 4) {
      setGlnTypeID(3);
    }
  }, []);

  const data_to_send = {
    registrationID: registrationid,
    companyName: companyname,
    glnTypeId: glnTypeId,
    addressName: AddressName,
    // pcnNumber: "string",
    // country: "string",
    state: State,
    // localGovt: "string",
    // department: "string",
    // sector: "string",
    city: City,
    street: Street,
    landMark: LandMark,
    nearestBustop: NearestBusStop,
    phoneNumber: PhoneNumber,
    url: URL,
    portNumber: PortNumber,
    ipAddress: IPAddress,
    functionalName: functionName,
    fuctionalDescription: functionDescription,
    longitude: longitude,
    latitude: latitude,
    isOwnedBy: isOnwedBy,
    affiliationTo: affiliationTo,
    affiliation: affiliation,
    openingDate: openningDate,
    postalCode: PostalCode,
  };

  const saveGLNDet = async () => {
    try {
      dispatch({ type: "SAVE_PRODUCTS" });
      await axios({
        url: `${process.env.REACT_APP_BASE_URL}glninformation/create?registrationID=${registrationid}`,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: data_to_send,
      })
        .then((res) => {
          if(res.data.isSuccess==true){
            toast.success("GLN SAVED!");
              setTimeout(() => {
                window.location.href = "/user/viewglns";
                
              }, 3000);

          }else{
            toast.warn(res.data.message);
            setTimeout(() => {
              window.location.href = "/user/viewglns";
              
            }, 5000);
            // dispatch({ type: "PREVIOUS_FORM", payload: 0 });
          }
        })
        //   if (
        //     (res.data.message =
        //       "Successfully sent location information for approval.")
        //   ) {
        //     toast.success("GLN SAVED!");
        //   }
        //   window.location.href = "/user/viewglns";
        //   // dispatch({ type: "SAVED_OR_WHATEVER" });
        //   // dispatch({ type: "CLEAR" });
        // })
        .catch((er) => {
          toast.warn("Please try again after some time");
          dispatch({ type: "PREVIOUS_FORM", payload: 0 });
          toast.error("Please retry");
        });
    } catch (err) {
      toast.error("Please retry");
      dispatch({ type: "PREVIOUS_FORM", payload: 0 });
      // console.log("error", err);
    }
  };

  return (
    <>
      <br />
      <CContainer>
        <h3>Details Review</h3>
        <hr />
        <CRow>
          <CCol>
            <p>
              <b>GLN TYPE:</b> {selectedgln}
            </p>
          </CCol>
          <CCol>
            <p>
              <b>Opening Date:{openningDate}</b>
            </p>
          </CCol>
          <CCol>
            {/* <p>packaging type:{selectedPackage}</p> */}
            <p>Affiliation To:{affiliationTo}</p>
          </CCol>
          <CCol>
            <p>Longitude:{longitude} </p>
          </CCol>
        </CRow>
        {/* Batch 2 information */}
        <CRow>
          <CCol>
            <p>
              <b>Latitude:</b> {latitude}
            </p>
          </CCol>
          <CCol>
            <p>
              <b>Functional Name: {functionName}</b>
            </p>
          </CCol>
          <CCol>
            <p>Functional Description:{functionDescription}</p>
          </CCol>
          <CCol>
            {/* <p>
              Span:{lifespan} {""} {lifespanunit}
            </p> */}
          </CCol>
        </CRow>

        <CRow>
          <CCol>{/* <p>Ingredients:{ingredients}</p> */}</CCol>
          <CCol>
            <p>
              <b>
                {/* Storage instruction:
                {storageinstruction} */}
              </b>
            </p>
          </CCol>
          <CCol>{/* <p>Usage instruction:{directionofuse}</p> */}</CCol>
          <CCol>
            <p>{/* Marketing message:{""} {marketingmessage} */}</p>
          </CCol>
        </CRow>
        <hr />
        <CRow>
          <CCol>
            <p>{/* NAFDAC Number:{""} {nafdacnumber} */}</p>
          </CCol>
          <hr></hr>
          {/* {toggleHealthCare && (
            <CRow>
              <CCol>
                <p>Generic Name:{genericName}</p>
              </CCol>
              <CCol>
                <p>
                  <b>
                    Strength:
                    {strength}
                  </b>
                </p>
              </CCol>
              <CCol>
                <p>Functional Name:{functionalName}</p>
              </CCol>
              <CCol>
                <p>
                  Route of administration:{""} {routeOfAdministration}
                </p>
              </CCol>
            </CRow>
          )}
          {toggleHealthCare && (
            <CRow>
              <CCol>
                <p>ATC Code:{atcCode}</p>
              </CCol>
              <CCol>
                <p>
                  <b>Trade item description: {tradeItemDescription}</b>
                </p>
              </CCol>
              <CCol>
                <p>Product form: {productForm}</p>
              </CCol>
              <CCol>
                <p>Shelf life: {shelfLife}</p>
              </CCol>
            </CRow>
          )}
          {toggleHealthCare && (
            <CRow>
              <CCol>
                <p>Pack size:{packSize}</p>
              </CCol>
              <CCol>
                <p>
                  <b>Quantity: {quantity}</b>
                </p>
              </CCol>
            </CRow>
          )} */}
        </CRow>

        <CButton
          onClick={() => dispatch({ type: "BACK_TO_FUNCTION", payload: 1 })}
          shape="rounded-pill"
        >
          <i className=" fa fa-arrow-left " /> Previous page
        </CButton>

        <CButton
          onClick={saveGLNDet}
          shape="rounded-pill"
          style={{ marginLeft: "10px" }}
        >
          {/* {postProduct && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )} */}
          Save request
        </CButton>
      </CContainer>
    </>
  );
};

export default ShowGLNDetails;
