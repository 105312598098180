import React from "react";
import {
  CContainer,
  CRow,
  CCol,
  CForm,
  CButton,
  CFormInput,
  CFormLabel,
  CFormCheck,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
} from "@coreui/react";
// import { CFormSwitch } from '@coreui/react'
import { dayMonth, measurement } from "../../fakedata/packaging";
import { useState } from "react";
import { useProductListingContext } from "../../context/productListingContext";
import HealthCareAttribute from "../healthCareAttribute";
import { RenderInput, RenderSelect, RenderTextArea } from "../../common/form";

const Step4 = ({}) => {
  // const [measure, setMeasure] = useState(measurement);
  const [dayOrWeek, setDayOrWeek] = useState(dayMonth);
  // const [toggleHealthCare, setToggleHealthCare] = useState(false);

  const {
    nextPage,
    previousPage,
    netcontent_id,
    netweight,
    nafdacnumber,
    functionalName,
    genericName,
    tradeItemDescription,
    strength,
    activeIngredients,
    productForm,
    atcCode,
    routeOfAdministration,
    shelfLife,
    packSize,
    quantity,
    lifespan,
    directionOfUse,
    directionofuse,
    primaryPackaginMaterial,
    completedHcInfo,
    toggleHealthCare,
    handleChange,
    units,
    dispatch,
  } = useProductListingContext();

  const listAsHealthCare = () => {
    // return setToggleHealthCare(!toggleHealthCare);
    dispatch({ type: "LIST_AS_HEALTHCARE" });
    // console.log("This guys was to");
  };
  const unitOption = units.map((u) => {
    const container = {};

    container.value = u.id;
    container.id = u.id;
    container.label = u.name;

    return container;
  });

  // console.log(unitOption);

  const renderReviewBtn = () => {
    if (toggleHealthCare) {
      if (
        functionalName == "" ||
        genericName == "" ||
        tradeItemDescription == "" ||
        strength == "" ||
        activeIngredients == "" ||
        productForm == "" ||
        atcCode == "" ||
        routeOfAdministration == "" ||
        shelfLife == "" ||
        packSize == "" ||
        quantity == "" ||
        directionOfUse == "" ||
        nafdacnumber == "" ||
        netcontent_id == ""||
        netweight==""
      ) {
        // console.log("functionall name", functionalName)
        // console.log("shelf", shelfLife)
        return (
          <p style={{ color: "orangered", marginTop: "2px" }}>
            Kindly fill all additional healthcare information to proceed{" "}
          </p>
        );
      } else {
        return (
          <button
            className="bg-gs1-color-orange text-white h-10 w-36 my-3"
            onClick={nextPage}
            // style={{ marginLeft: 10 }}
            // shape="rounded-pill"
          >
            review details {""} <i className=" fa fa-arrow-right " />
          </button>
        );
      }
    } else if (!toggleHealthCare) {
      if (
        netweight !== "" &&
        netcontent_id !== "choose category" &&
        lifespan !== ""
        && netweight !==""
      ) {
        return (
          <button
            onClick={nextPage}
            className="bg-gs1-color-orange text-white h-10 w-36 my-3"
            shape="rounded-pill"
          >
            review details <i className=" fa fa-arrow-right " />
          </button>
        );
      } else {
        return (
          <p style={{ color: "orangered" }}>
            Please fill the important information{" "}
          </p>
        );
      }
    }
  };
  return (
    <>
      <CContainer>
        <h3> Physical attributes </h3>{" "}
        <p> Explanation of physical attributes </p>
        <CForm className="row g-3">
          <CCol md={4}>
            {" "}
            {RenderSelect("netcontent_id", "Unit of measurement", units)}{" "}
          </CCol>{" "}
        
       
          <CCol md={4}> 
          <label for="" class="form-label">LifeSpan</label>
          <input
              min="0"  step="1"
                type="number"
                class="form-control"
                name="lifespan"
                onChange={(e) => handleChange(e, "lifespan")}
                id="lifespan"
                aria-describedby="helpId"
                placeholder=""
              />
           </CCol>{" "}
           
          <CCol md={4}>
            {" "}
            {RenderSelect("lifespanunit", "D/W/Y", dayOrWeek)}{" "}
          </CCol>{" "}
          <CCol md={4}>
            {" "}
            
            <label for="" class="form-label">Net Content</label>
          <input
              min="0"  step="1"
                type="number"
                class="form-control"
                name="netweight"
                id="netweight"
                onChange={(e) => handleChange(e, "netweight")}
                aria-describedby="helpId"
                placeholder=""
              />
           
          </CCol>{" "}
          <CCol md={4}> {RenderTextArea("ingredients", "Ingredients")} </CCol>{" "}
          <CCol md={4}>
            {" "}
            {RenderTextArea("storageinstruction", "Storage Instruction")}{" "}
          </CCol>{" "}
          <CCol md={4}>
            {" "}
            {RenderTextArea("directionofuse", "Instruction of use")}{" "}
          </CCol>{" "}
          <CCol md={4}>
            {" "}
            {RenderTextArea("marketingmessage", "Marketing information")}{" "}
          </CCol>{" "}
          <hr />
          <CCol md={4}>
            <CFormLabel htmlFor=""> NAFDAC Number </CFormLabel>{" "}
            <CFormInput
              type="text"
              name="nafdacnumber"
              id=""
              className={toggleHealthCare ? "nafdac" : " "}
              defaultValue={nafdacnumber}
              onChange={(e) => handleChange(e, "nafdacnumber")}
            />{" "}
          </CCol>{" "}
          <CCol md={4}> </CCol>{" "}
          <CCol md={4}>
            <CFormLabel htmlFor="" style={{ color: "#1565c0" }}>
              * Toggle to list as Healthcare produts *
            </CFormLabel>{" "}
            <CFormSwitch
              onClick={listAsHealthCare}
              size="xl"
              id="formSwitchCheckDefaultXL"
            />
          </CCol>{" "}
          <hr /> {toggleHealthCare && <HealthCareAttribute />}
          <CCol xs={12}>
            <button
              className="bg-gs1-color-blue text-white h-10 w-36 my-3 cursor-pointer"
              onClick={previousPage}
              shape="rounded-pill"
            >
              <i className=" fa fa-arrow-left " /> Previous page{" "}
            </button>{" "}
            {renderReviewBtn()}{" "}
          </CCol>{" "}
        </CForm>{" "}
      </CContainer>{" "}
    </>
  );
};

export default Step4;
