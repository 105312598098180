import { initial } from "lodash";
import React, { useContext, useReducer, useEffect } from "react";
import reducer from "../reducers/glnReducer";
import { toast } from "react-toastify";
import axios from "axios";
import {
  getBrandDetails,
  getPackagingLevel,
  getPackagingType,
  getunitOfMeasure,
  getTargetmarket,
  getBrickCategory,
  listedProducts,
  getGTinFeedetails,
  generateBarcode,
  getGLnRecords,
  getCountries,
  getStates
} from "../services/generalService";
import {
  NEXT_STAGE,
  PREVIOUS_STAGE,
  HANDLE_PACKAGING,
  LISTED_BRANDS,
  PACKAGING_TYPES,
  UPDATE_INPUT,
  UPDATE_MARKET,
  PACKAGING_LEVEL,
  ERROR_FOUND,
} from "../utils/action";

const defaultState = {
  glns: "",
  glnType: 0,
  level: 0,
  selectedgln: "",
  affiliation: "",
  affiliationTo: "",
  isOnwedBy: "",
  openningDate: "",
  latitude: "",
  longitude: "",
  functionName: "",
  functionDescription: "",
  IPAddress: "",
  PortNumber: "",
  URL: "",
  PhoneNumber: "",
  Email: "",
  Street: "",
  City: "",
  State: "",
  LandMark: "",
  NearestBusStop: "",
  PostalCode: "",
  AddressName: "",
  countries: [],
  selectedCountry: "",
  selectedState: "",
  states: [],
  addressInFocus: false,
  countryHasFocus: false,
  stateHasFocus: false,
  cityHasFocus: false,
  streetHasFocus: false,
  fetchingCoord:false
};
const getStoredItems = () => {
  const a = sessionStorage.getItem("company");
  return a ? JSON.parse(a) : {};
};

const { registrationid } = getStoredItems();

const GlnContext = React.createContext();
const GlnProvider = ({ children }) => {
  // const [producListing, setProductListing] = useState(listing);
  const [state, dispatch] = useReducer(reducer, defaultState);
  //Next stage
  const handlenext = () => {
    // console.log("some things");
    if (state.selectedgln === 1) {
      dispatch({ type: "NEXT_STAGE", payload: 1 });
    } else if (state.selectedgln === 2) {
      // console.log("lokomnaaa");
      dispatch({ type: "NEXT_STAGE", payload: 2 });
    } else if (state.selectedgln === 3) {
      dispatch({ type: "NEXT_STAGE", payload: 3 });
    } else if (state.selectedgln === 4) {
      dispatch({ type: "NEXT_STAGE", payload: 4 });
    }
  };
  //previous stage

  useEffect(() => {
    const getGLNs = async () => {
      try {
        const data = (await getGLnRecords(registrationid)).data;
        // console.log("Data", data.returnedObject.glnInformationList);
        dispatch({
          type: "SET_GLNS",
          payload: data.returnedObject.glnInformationList,
        });
      } catch (ex) {
        toast("Something went wrong with units of measurement");
      }
    };
    const _getCountries = async () => {
      try {
        const data = (await getCountries()).data;
        // console.log("Data", data.returnedObject.glnInformationList);
        dispatch({
          type: "SET_Countries",
          payload: data.returnedObject,
        });
      } catch (ex) {
        toast("Something went wrong with units of measurement");
      }
    };
    getGLNs();
    _getCountries();
  }, []);

  const previousPage = () => {
    dispatch({ type: "PREVIOUS_STAGE" });
  };

  const handleSelection = (id) => {
    dispatch({ type: "HANDLE_GLNSELECTED", payload: id });
  };
  const handleCountrySelection = (ct) => {
    dispatch({ type: "SET_SELECTED_COUNTRY", payload: ct });
  };
  const handleStateSelection = (st) => {
    dispatch({ type: "SET_SELECTED_STATE", payload: st });
  };
  const setCountryFocus = (f) => {
    dispatch({ type: "SET_COUNTRY_FOCUS", payload: f });
  };
  const setStateFocus = (f) => {
    dispatch({ type: "SET_STATE_FOCUS", payload: f });
  };
  const setCityFocus = (f) => {
    dispatch({ type: "SET_CITY_FOCUS", payload: f });
  };
  const setStreetFocus = (f) => {
    dispatch({ type: "SET_STATE_FOCUS", payload: f });
  };

  useEffect(async () => {

    try {
      if(state.selectedCountry.value !=undefined ){
        const data = (await getStates(state.selectedCountry.value)).data;
        console.log("states", data);
        dispatch({
          type: "SET_States",
          payload: data.returnedObject,
        });
      }

      
    } catch (ex) {
      toast("Something went wrong with states");
    }


  }, [state.selectedCountry]);

  useEffect(async () => {

    try {
      if (
        state.selectedCountry != "" & state.selectedState != "" && state.City != "" && state.Street != "" &&
        !state.countryHasFocus && !state.stateHasFocus && !state.cityHasFocus && !state.streetHasFocus
      ) {

        let fulladdress = state.Street + ", " + state.City + ", " + state.selectedState.label + ", " + state.selectedCountry.label;

        console.log("fetch geocoding .......")
        console.log(fulladdress);
        dispatch({
          type: "SET_FETCH_COORD",
          payload: true,
        });

        await axios({
          url: `https://locationregistry-qa.gs1ng.org/Geocoding/geocode`,
          method: "POST",
          headers: {
            ContentType: "multipart/form-data",
          },
          data: [fulladdress],
        })
          .then((res) => {
            if (res.data.status == 1) {
              let xlat = res.data.returnedObject[0].latitude;
              let xlng = res.data.returnedObject[0].longitude;
              dispatch({
                type: "UPDATE_INPUT",
                value: xlat,
                name: "latitude",
              });
              dispatch({
                type: "UPDATE_INPUT",
                value: xlng,
                name: "longitude",
              });
              dispatch({
                type: "SET_FETCH_COORD",
                payload: false,
              });
              toast.success("geo cordinate infered");

            } else {
              toast.warn("geo cordinate can not be infer, please provide");
              dispatch({
                type: "SET_FETCH_COORD",
                payload: false,
              });


            }

          })
          .catch((er) => {
            toast.warn("Something went wrong, contact GS1 Nigeria");
            dispatch({
              type: "SET_FETCH_COORD",
              payload: false,
            });
            // toast.error("Please retry");
          });


      }
    } catch (ex) {
      toast("Something went wrong with states");
    }


  }, [
    state.selectedCountry, state.selectedState, state.City, state.Street,
    state.countryHasFocus, state.stateHasFocus, state.cityHasFocus, state.streetHasFocus
  ]);

  const handleChange = (e, fieldname) => {
    dispatch({
      type: "UPDATE_INPUT",
      value: e.target.value,
      name: fieldname,
    });
  };

  return (
    <GlnContext.Provider
      value={{
        ...state,
        dispatch,
        handlenext,
        previousPage,
        handleChange,
        handleSelection,
        handleCountrySelection,
        handleStateSelection,
        setCountryFocus,
        setStateFocus,
        setCityFocus,
        setStreetFocus
      }}
    >
      {children}{" "}
    </GlnContext.Provider>
  );
};

export const useGLNContext = () => {
  return useContext(GlnContext);
};

export { GlnContext, GlnProvider };
