import DashBoardView from "./dasnBoardNav";

import { Outlet } from "react-router-dom";
import SideBar from "./sideBar";

import { Navigate, useNavigate } from "react-router-dom";
const tokks = sessionStorage.getItem("company");

const UserLayout = () => {
  //   const status = useSelector(profile_stat);
  //   const name = useSelector(firstName) ;
  const moveTo = useNavigate();
  // console.log("my", tokks);

  let auth = { tokken: tokks };
  return auth.tokken ? (
    <>
      <div className="gid grid-col-2">
        <div className="col-start-1 sm:z-10 sm:basis-0 lg:overflow-auto sm:overflow-y-hidden absolute">
          <SideBar className="" />
        </div>

        <div className="lg:ml-[240px] sm:col-start-1 lg:col-span-2  px-15">
          <DashBoardView />
          {/* {status == "incomplete" || status == "" ? (
            <p className="px-4 font-sand text-sm py-2 text-white bg-[#790b63] animate-pulse cursor-pointer">
              Hello {name} kindly complete your profile information to enable us
              serve you better.{" "}
              <span
                className="font-bold underline"
                onClick={() => moveTo("/userHome/settings", { state: 1 })}
              >
                Click here !
              </span>
            </p>
          ) : (
            ""
          )} */}
          <Outlet />
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default UserLayout;
