import React from "react";
import { CFormLabel, CFormTextarea } from "@coreui/react";

const TextArea = ({ name, label, ...rest }) => {
  return (
    <>
      <CFormLabel htmlFor="">{label}</CFormLabel>
      <CFormTextarea name={name} {...rest} rows="2"></CFormTextarea>
    </>
  );
};

export default TextArea;
