import { NEXT_STAGE, SPINNER, STOP_SPINNER } from "../utils/action";
const reducer = (state, action) => {
  // const { level } = state;
  switch (action.type) {
    case SPINNER:
      return { ...state, loginLoader: true };
    case STOP_SPINNER:
      return { ...state, loginLoader: false };
    case "DASHBOARD_DATA":
      const {
        totalGtinsRequested,
        usedGtins,
        remainingGtins,
        usedImages,
        nextRenewalAmount,
        totalImagesRequested,
        nextPaymentDate,
        pendingImageRequest,
        remainingImages,
        totalUsed,
        totalRemaining,
        pendingGCPStatus,
      } = action.payload;
      return {
        ...state,
        totalGtinsRequested,
        usedGtins,
        remainingGtins,
        usedImages,
        pendingImageRequest,
        remainingImages,
        totalUsed,
        totalRemaining,
        nextRenewalAmount,
        nextPaymentDate,
        pendingGCPStatus,
        totalImagesRequested,
        usedImages,
      };
    case "IMAGE_DOWNLOADED":
      return { ...state, imageDownloaded: "image downloaded" };
    case "SET_MENU":
      return { ...state, menustatus: !state.menustatus };
    case "MOBILE_MENU":
      return { ...state, menustatus: false };

    default:
      break;
  }
};

export default reducer;
