import {
  CContainer,
  CRow,
  CCol,
  CForm,
  CButton,
  CFormInput,
  CFormLabel,
  CFormCheck,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CTooltip,
} from "@coreui/react";
import { AiFillInfoCircle } from "react-icons/ai";
import Select from "react-select";
// import { CDatePicker } from "@coreui/react-pro@next";
import React, { useState, useEffect } from "react";

import { useGLNContext } from "../../context/glnContext";
import { useITFContext } from "../../context/itfContext";
import ITF14Review from './ITF14DetailReview'



const ITF14Form = () => {
  const {
    selectedtype,
    products,
    description,
    itfinfo,
    dispatch,
    handleChange,
    handleITFSelection,
    handleITFItemSelection,
    handleITFLevelSelection,
    level,
    showreview,
    itemcount

  } = useITFContext();
  // console.log("opening", openningDate);

  const productoption = products.map((item) => {
    const container = {};

    container.value = item.id;
    container.label = item.gtin +" - "+ item.productdescription;

    return container;
  });

  const leveloption = [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
    const container = {};

    container.value = item;
    container.label = item;

    return container;
  });
  if (showreview == 1) {
    return <ITF14Review />
  }

  const renderSelect = () => {
    if (selectedtype == "HETEROGENOUS") {
      return (
        <Select
          className="dropdown"
          placeholder="select items"
          value={itfinfo}
          options={productoption}
          onChange={(e) => handleITFItemSelection(e, "ItemList")} // assign onChange function
          isMulti
          isClearable
          name="gtins"


        />

      )
    } else {

      return (
        <Select
          className="dropdown"
          placeholder="select items"
          value={itfinfo}
          options={productoption}
          onChange={(e) => handleITFItemSelection(e, "ItemList")} // assign onChange function
          isMulti={false}
          isClearable
          name="gtins"


        />
      )

    }
  }

  const preview = () => {
    if (
      description == "" ||
      itfinfo == []
    ) {
      return (
        <CButton
          color="primary"
          style={{ marginLeft: "5px" }}
          shape="rounded-pill"
          disabled
        >
          Please fill in all the fields
        </CButton>
      );
    } else {
      return (
        <CButton
          color="primary"
          style={{ marginLeft: "5px" }}
          shape="rounded-pill"
          onClick={() => dispatch({ type: "MOVE_TO_REVIEW", payload: 1 })}
        >
          Review <i className="fa fa-arrow-right " />
        </CButton>
      );
    }
  };

  return (
    <>
      <h3>ITF-14 Information</h3>
      <p style={{ color: "red" }}>*please note that all field are mandetory*</p>
      <hr />
      <CForm
        className="row g-3"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >

        <CRow className="mt-3">
          <CCol sm={12}>
            <div className="d-flex justify-content-between">
              <CFormLabel>Description:
              </CFormLabel>
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="describe the box"
              >
                <span>
                  <AiFillInfoCircle color="pupple" size="20" />
                </span>
              </CTooltip>
            </div>
            <CFormTextarea
              name="description"
              rows={2}
              defaultValue={description}

              onChange={(e) => handleChange(e, "description")}
            />
          </CCol>
        </CRow>

          <CRow className="mt-5">
            <CCol md={6}>
              <div className="d-flex justify-content-between">
                <CFormLabel>Select Items:
                </CFormLabel>
                <CTooltip
                  trigger={"hover"}
                  placement="top"
                  content="select item(s) you want to create itf-14 for"
                >
                  <span>
                    <AiFillInfoCircle color="pupple" size="20" />
                  </span>
                </CTooltip>
              </div>

              {renderSelect()}
            </CCol>

            {/* <CCol md={4}>
              <div className="d-flex justify-content-between">
                <CFormLabel>Package Level:
                </CFormLabel>
                <CTooltip
                  trigger={"hover"}
                  placement="top"
                  content="select the appropriate ITF-14 hierachy level"
                >
                  <span>
                    <AiFillInfoCircle color="pupple" size="20" />
                  </span>
                </CTooltip>
              </div>

              <Select
                className="dropdown"
                placeholder="select pack level"
                value={level}
                options={leveloption}
                onChange={(e) => handleITFLevelSelection(e, "PackLevel")} // assign onChange function
                isMulti={false}
                isClearable
                name="level"


              />
            </CCol>*/}
            <CCol md={6}> 
          <div className="d-flex justify-content-between">
            <CFormLabel>Total Content Count:          
            </CFormLabel>
            <CTooltip
                  trigger={"hover"}
                  placement="top"
                  content="Immediate content count"
                >
                  <span>
                    <AiFillInfoCircle color="pupple" size="20" />
                  </span>
                </CTooltip>
          </div>
          <CFormInput
            name="itemcount"
            defaultValue={itemcount}
            type="text"
            onChange={(e) => handleChange(e, "itemcount")}
          />
        </CCol>
          </CRow>



        <CCol xs={12}>
          <CButton
            style={{ marginLeft: 10 }}
            shape="rounded-pill"
            onClick={() => dispatch({ type: "PREVIOUS_FORM", payload: 0 })}
          >
            <i className=" fa fa-arrow-left " /> previous page
          </CButton>
          {preview()}
        </CCol>
      </CForm>
    </>
  );
};

export default ITF14Form;
