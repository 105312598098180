import {
  CCard,
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CFormLabel,
  CFormInput,
  CButton,
  CSpinner,
} from "@coreui/react";
import { useState } from "react";
import axios from "axios";
import { useUserContext } from "../context/userContext";
import { toast } from "react-toastify";

const ChangeMypass = () => {
  const { email } = useUserContext();
  const [currentpassword, setCurrentpassword] = useState("");
  const [newpassword, setNewPassWord] = useState("");
  const [loading, setLoading] = useState(false);

  const submitPassChange = async () => {
    console.log("What is happenning oooo");
    try {
      await axios({
        url: "https://membershipservices.gs1ng.org/api/v2/authentication/passwordchange",
        method: "POST",
        data: { newpassword, currentpassword, email },
      })
        .then((res) => {
          if (res.data.isSuccess == true) {
            toast(res.data.message);
          } else {
            toast.warn(res.data.message);
          }
        })
        .catch((er) => {
          toast.warn("Please try again after some time");
        });
    } catch (err) {
      // dispatch({ type: "SAVED_OR_WHATEVER" });
      // console.log("error", err);
    }
  };

  return (
    <div className="mx-3 my-3">
      <CCard className="mt-10">
        <CCardBody className="gtin-card">
          <h3 className="">Change Password</h3>
          <hr />
          <CRow>
            <CCol>
              <CFormLabel htmlFor="exampleFormControlInput1">
                Old password
              </CFormLabel>
              <CFormInput
                onChange={(e) => setCurrentpassword(e.target.value)}
                type="text"
                id="exampleFormControlInput1"
                placeholder="******"
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
            <CCol>
              <CFormLabel htmlFor="exampleFormControlInput1">
                New Password
              </CFormLabel>
              <CFormInput
                onChange={(e) => setNewPassWord(e.target.value)}
                type="text"
                id="exampleFormControlInput1"
                placeholder="************"
                aria-describedby="exampleFormControlInputHelpInline"
              />
            </CCol>
          </CRow>
          <button
            className="w-32 mt-5 bg-gs1-blue text-white h-10"
            onClick={submitPassChange}
          >
            {loading && (
              <CSpinner component="span" size="sm" aria-hidden="true" />
            )}
            Submit
          </button>
        </CCardBody>
      </CCard>
      <br />
    </div>
  );
};

export default ChangeMypass;
