import {
  CContainer,
  CRow,
  CCol,
  CForm,
  CButton,
  CFormInput,
  CFormLabel,
  CFormCheck,
  CFormSelect,
  CFormSwitch,
  CTooltip,
} from "@coreui/react";

import Select from "react-select";
// import { CDatePicker } from "@coreui/react-pro@next";
import React, { useState, useEffect } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { useGLNContext } from "../../context/glnContext";

// import { options } from "joi-browser";
// import { set } from "lodash";
// import { forEach } from "lodash";

const Mobile = () => {
  const {
    openningDate,
    handleChange,
    longitude,
    functionName,
    latitude,
    functionDescription,
    previousPage,
    affiliation,
    affiliationTo,
    dispatch,
    Email,
    AddressName,
  } = useGLNContext();
  // console.log("opening", openningDate);

  const preview = () => {
    if (
      openningDate == "" ||
      longitude == "" ||
      // functionName == "" ||
      latitude == "" ||
      AddressName == "" ||
      Email == ""
    ) {
      return (
        <CButton
          color="primary"
          style={{ marginLeft: "5px" }}
          shape="rounded-pill"
          disabled
        >
          Please fill in all the fields
        </CButton>
      );
    } else {
      return (
        <CButton
          color="primary"
          style={{ marginLeft: "5px" }}
          shape="rounded-pill"
          onClick={() => dispatch({ type: "FINAL_STAGE", payload: 6 })}
        >
          Review <i className="fa fa-arrow-right " />
        </CButton>
      );
    }
  };

  return (
    <>
      <h3>Mobile GLN Location</h3>
      <p style={{ color: "red" }}>*please note that all field are mandetory*</p>
      <hr />
      <CForm
        className="row g-3"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <CCol md={4}>
        <div className="d-flex justify-content-between">
            <CFormLabel>Opening Days:          
            </CFormLabel>
            <CTooltip
                  trigger={"hover"}
                  placement="top"
                  content="The days of the week that this location open for operation. e.g Monday-Friday"
                >
                  <span>
                    <AiFillInfoCircle color="pupple" size="20" />
                  </span>
                </CTooltip>
          </div>
          <CFormInput
            name="openningDate"
            defaultValue={openningDate}
            type="text"
            onChange={(e) => handleChange(e, "openningDate")}
          />
        </CCol>
        {/* <CCol md={4}>
          <CFormLabel>Affiliation To:</CFormLabel>
          <CFormInput
            name=""
            defaultValue={affiliationTo}
            type="text"
            onChange={(e) => handleChange(e, "affiliationTo")}
          />
        </CCol> */}
        <CCol md={4}>
        <div className="d-flex justify-content-between">
          <CFormLabel>Affiliation:</CFormLabel>
           <CTooltip
                  trigger={"hover"}
                  placement="top"
                  content="Corporate
                  Social
                  Responsibility
                  Affiliation. A URI value (website address) linking to
                  information related to social
                  responsibility activities and
                  associations that a location is
                  involved with."
                >
                  <span>
                    <AiFillInfoCircle color="pupple" size="20" />
                  </span>
                </CTooltip>
                </div>
          <CFormInput
            name=""
            defaultValue={affiliation}
            type="text"
            onChange={(e) => handleChange(e, "affiliation")}
          />
        </CCol>

        <CCol md={4}>
        <div className="d-flex justify-content-between">
          <CFormLabel htmlFor="">Longitude</CFormLabel>
           <CTooltip
                  trigger={"hover"}
                  placement="top"
                  content="Geographic coordinate that specifies the east–west position of a point on the surface of the Earth. It can quicky be gotten by searching the location address in google map"
                >
                  <span>
                    <AiFillInfoCircle color="pupple" size="20" />
                  </span>
                </CTooltip>
                </div>
          <CFormInput
            type="text"
            name="longitude"
            id=""
            defaultValue={longitude}
            onChange={(e) => handleChange(e, "longitude")}
          />
        </CCol>
        <CCol md={4}>
        <div className="d-flex justify-content-between">
          <CFormLabel htmlFor="">Latitude</CFormLabel>
           <CTooltip
                  trigger={"hover"}
                  placement="top"
                  content="Latitude is a measurement of a location north or south of the Equator.It can quicky be gotten by searching the location address in google map"
                >
                  <span>
                    <AiFillInfoCircle color="pupple" size="20" />
                  </span>
                </CTooltip>
                </div>
          <CFormInput
            type="text"
            name="longitude"
            id=""
            defaultValue={latitude}
            onChange={(e) => handleChange(e, "latitude")}
          />
        </CCol>
        <CCol md={4}>
          <CFormLabel htmlFor="">Address Name</CFormLabel>
          <CFormInput
            type="text"
            name="AddressName"
            id=""
            defaultValue={AddressName}
            onChange={(e) => handleChange(e, "AddressName")}
          />
        </CCol>

        <CCol md={4}>
          <CFormLabel htmlFor="">Email</CFormLabel>
          <CFormInput
            type="text"
            name="Email"
            id=""
            defaultValue={Email}
            onChange={(e) => handleChange(e, "Email")}
          />
        </CCol>

        <CCol xs={12}>
          <CButton
            style={{ marginLeft: 10 }}
            shape="rounded-pill"
            onClick={() => dispatch({ type: "PREVIOUS_FORM", payload: 0 })}
          >
            <i className=" fa fa-arrow-left " /> previous page
          </CButton>
          {preview()}
        </CCol>
      </CForm>
    </>
  );
};

export default Mobile;
