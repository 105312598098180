import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// import { config } from "dotenv";
axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`
//https://onesourceadminportal-qa.gs1ng.org
const token = sessionStorage.getItem('tokenValidation')
axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(token)}`;
//This line uses axios interseptors 
axios.interceptors.request.use(null,

    (error) => {
        const expectedError =
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500;
        if (!expectedError) {
            toast.error("Oh my something just went wrong!");
        }

        return Promise.reject(error);
    });
// export const fetchJwt = () => {}
export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
}