import { CContainer } from "@coreui/react";
import { React, useState, useEffect } from "react";
import { CRow, CCol, CForm, CButton, CSpinner } from "@coreui/react";
import { targetMarket } from "./../../fakedata/market";
import { useProductListingContext } from "../../context/productListingContext";
// import { useEffect } from "react";
import { saveProduct } from "../../services/generalService";
import { toast } from "react-toastify";
import { fromPairs } from "lodash";
import axios from "axios";
const ShowDetailS = () => {
  // const [targetMarkets, setTargetMarket] = useState([]);
  const {
    previousPage,
    productdescription,
    selectedmarket,
    TargetMarketList,
    packaginglevel_id,
    directionofuse,
    FrontImageShow,
    BackImageShow,
    netweight,
    dispatch,
    dispImg1,
    dispImg2,
    toggleHealthCare,
    targetedMarket,
    packagingtype_id,
    marketingmessage,
    loadPackageType,
    selectedPackage,
    brandinformation_id,
    consumerfirstavailabilitydate,
    FrontImage,
    BackImage,
    netcontent_id,
    storageinstruction,
    brands,
    lifespan,
    lifespanunit,
    ingredients,
    // directionofuse,
    postProduct,
    brickcategory_id,
    functionalName,
    genericName,
    tradeItemDescription,
    manufacturer,
    manufacturerAddress,
    strength,
    activeIngredients,
    ActiveIngredient,
    productForm,
    atcCode,
    routeOfAdministration,
    shelfLife,
    globalProductCategory,
    packSize,
    quantity,
    directionOfUse,
    primaryPackaginMaterial,

    nafdacnumber,
  } = useProductListingContext();

  // console.log("front image", FrontImage);
  const targeted_market = targetedMarket.map((item) => {
    const container = {};

    container.value = item.id;
    container.label = item.name;

    return container;
  });

  //Remove the loop here latter... There is no need for a loop
  useEffect(() => {
    const getPackageValue = () => {
      var x = "";
      for (let i = 0; i < packagingtype_id.length; i++) {
        // console.log("shit", i);
        var p = loadPackageType.filter((x) => x.id == packagingtype_id);
        // console.log("only p", p[0].name);
        x = p[0].name;
      }
      dispatch({ type: "SHOW_PACKAGE", payload: x });
    };
    getPackageValue();
  }, [packagingtype_id]);

  const saveProducDet = async () => {
    let formdata = new FormData();
    formdata.append("Product.netcontent_id", netcontent_id);
    formdata.append(
      "Product.consumerfirstavailabilitydate",
      consumerfirstavailabilitydate
    );
    formdata.append("Product.productdescription", productdescription);
    formdata.append("Product.packagingtype_id", parseInt(packagingtype_id));
    formdata.append("Product.netweight", parseFloat(netweight));
    formdata.append("Product.brickcategory_id", parseInt(brickcategory_id));
    formdata.append(
      "Product.brandinformation_id",
      parseInt(brandinformation_id)
    );
    formdata.append("Product.packaginglevel_id", parseInt(packaginglevel_id));
    formdata.append("Product.ingredients", ingredients);
    formdata.append("Product.storageinstruction", storageinstruction);
    formdata.append("Product.directionofuse", directionofuse);
    formdata.append("Product.marketingmessage", marketingmessage);
    formdata.append("Product.lifespan", parseInt(lifespan));
    formdata.append("Product.lifespanunit", lifespanunit);
    formdata.append("TargetMarketList", TargetMarketList);
    formdata.append("ProductImageUpload.FrontImage", FrontImage);
    formdata.append("ProductImageUpload.BackImage", BackImage);
    formdata.append("Product.nafdacnumber", nafdacnumber);
    //pharmaproducts
    formdata.append("Product.IsPharma", toggleHealthCare);
    formdata.append("PharmaceuticalInformation.GenericName", genericName);
    formdata.append(
      "PharmaceuticalInformation.TradeItemDescription",
      tradeItemDescription
    );
    formdata.append(
      "PharmaceuticalInformation.Manufacturer",
      manufacturer
    );
    formdata.append(
      "PharmaceuticalInformation.ManufacturerAddress",
      manufacturerAddress
    );
    formdata.append("PharmaceuticalInformation.Strength", strength);
    formdata.append("PharmaceuticalInformation.ProductForm", productForm);
    formdata.append("PharmaceuticalInformation.FunctionalName", functionalName);
    formdata.append(
      "PharmaceuticalInformation.RouteOfAdministration",
      routeOfAdministration
    );
    formdata.append("PharmaceuticalInformation.ATCCode", atcCode);
    formdata.append("PharmaceuticalInformation.PackSize", packSize);
    formdata.append("PharmaceuticalInformation.Quantity", quantity);
    formdata.append("PharmaceuticalInformation.shelfLife", shelfLife);
    {
      toggleHealthCare == true
        ? formdata.append(
            "PharmaceuticalInformation.primaryPackaginMaterial",
            packagingtype_id
          )
        : formdata.append(
            "PharmaceuticalInformation.primaryPackaginMaterial",
            ""
          );
    }

    {
      toggleHealthCare == true
        ? formdata.append(
            "PharmaceuticalInformation.ActiveIngredient",
            activeIngredients
          )
        : formdata.append("PharmaceuticalInformation.ActiveIngredient", "");
    }
    formdata.append("PharmaceuticalInformation.DirectionOfUse", directionOfUse);
    try {
      dispatch({ type: "SAVE_PRODUCTS" });
      await axios({
        url: `${process.env.REACT_APP_BASE_URL}product/create`,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: formdata,
      })
        .then((res) => {
          // console.log(res);
          if (res.data.isSuccess == true) {
            toast.success(res.data.message);
            dispatch({ type: "SAVED_OR_WHATEVER" });
            dispatch({ type: "CLEAR" });
            window.location.href = "/user/viewproducts";
          } else {
            toast.info(res.data.message);
            dispatch({ type: "SAVED_OR_WHATEVER" });
          }
        })
        .catch((er) => {
          toast.warn("please retry");
          dispatch({ type: "SAVED_OR_WHATEVER" });
        });
    } catch (err) {
      dispatch({ type: "SAVED_OR_WHATEVER" });
      // console.log("error", err);
    }
  };

  return (
    <>
      <br />
      <CContainer>
        <h3>Details Review</h3>
        <hr />
        <CRow>
          <CCol>
            <img src={dispImg1} alt="" style={{ height: 100 }} />
          </CCol>
          <CCol>
            <img src={dispImg2} alt="" style={{ height: 100 }} />
          </CCol>
        </CRow>
        <hr />
        <CRow>
          <CCol>
            <p>
              <b>Product Description:</b> {productdescription}
            </p>
          </CCol>
          <CCol>
            <p>
              <b>
                Target Market:
                {selectedmarket.map((p) => {
                  return <li key={p}>{p}</li>;
                })}
              </b>
            </p>
          </CCol>
          <CCol>
            {/* <p>packaging type:{selectedPackage}</p> */}
            <p>packaging type:{selectedPackage}</p>
          </CCol>
          <CCol>
            <p>Brand info:{brandinformation_id} </p>
          </CCol>
        </CRow>
        {/* Batch 2 information */}
        <CRow>
          <CCol>
            <p>
              <b>Availability date:</b> {consumerfirstavailabilitydate}
            </p>
          </CCol>
          <CCol>
            <p>
              <b>Unit of measurement: {netcontent_id}</b>
            </p>
          </CCol>
          <CCol>
            <p>Net content:{netweight}</p>
          </CCol>
          <CCol>
            <p>
              Span:{lifespan} {""} {lifespanunit}
            </p>
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            <p>Ingredients:{ingredients}</p>
          </CCol>
          <CCol>
            <p>
              <b>
                Storage instruction:
                {storageinstruction}
              </b>
            </p>
          </CCol>
          <CCol>
            <p>Usage instruction:{directionofuse}</p>
          </CCol>
          <CCol>
            <p>
              Marketing message:{""} {marketingmessage}
            </p>
          </CCol>
        </CRow>
        <hr />
        <CRow>
          <CCol>
            <p>
              NAFDAC Number:{""} {nafdacnumber}
            </p>
          </CCol>
          <hr></hr>
          {toggleHealthCare && (
            <CRow>
              <CCol>
                <p>Generic Name:{genericName}</p>
              </CCol>
              <CCol>
                <p>
                  <b>
                    Strength:
                    {strength}
                  </b>
                </p>
              </CCol>
              <CCol>
                <p>Functional Name:{functionalName}</p>
              </CCol>
              <CCol>
                <p>
                  Route of administration:{""} {routeOfAdministration}
                </p>
              </CCol>
            </CRow>
          )}
          {toggleHealthCare && (
            <CRow>
              <CCol>
                <p>ATC Code:{atcCode}</p>
              </CCol>
              <CCol>
                <p>
                  <b>Trade item description: {tradeItemDescription}</b>
                </p>
              </CCol>
              <CCol>
                <p>Product form: {productForm}</p>
              </CCol>
              <CCol>
                <p>Shelf life: {shelfLife}</p>
              </CCol>
            </CRow>
          )}
          {toggleHealthCare && (
            <CRow>
              <CCol>
                <p>Pack size:{packSize}</p>
              </CCol>
              <CCol>
                <p>
                  <b>Quantity: {quantity}</b>
                </p>
              </CCol>
            </CRow>
          )}
          {toggleHealthCare && (
            <CRow>
              <CCol>
                <p>Manufacturer:{manufacturer}</p>
              </CCol>
              <CCol>
                <p>
                  <b>Manufacturer Address: {manufacturerAddress}</b>
                </p>
              </CCol>
            </CRow>
          )}
        </CRow>

        <button
          className="bg-gs1-color-blue mr-2 cursor-pointer text-white h-10 w-36 my-3"
          onClick={previousPage}
          shape="rounded-pill"
        >
          <i className=" fa fa-arrow-left " /> Previous page
        </button>

        <button
          disabled={postProduct}
          onClick={saveProducDet}
          className="bg-gs1-color-orange cursor-pointer text-white h-10 w-36 my-3"
          // shape="rounded-pill"
          // style={{ marginLeft: "10px" }}
        >
          {postProduct && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
          Save product
        </button>
      </CContainer>
    </>
  );
};

export default ShowDetailS;
