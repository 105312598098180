import { initial } from "lodash";
import React, { useContext, useReducer, useEffect } from "react";
import reducer from "../reducers/itf14Reducer";
import { toast } from "react-toastify";
import Select from "react-select";

import {
listedProducts,
getITF14s
} from "../services/generalService";

const defaultState = {
  selectedtype:"",
  description:"",
  itfinfo:[],
  products:"",
  showform:"",
  showreview:"",
  level:"",
  itfrecords:[]


};
const getStoredItems = () => {
  const a = sessionStorage.getItem("company");
  return a ? JSON.parse(a) : {};
};


const { registrationid } = getStoredItems();

const ITFContext = React.createContext();
const ITFProvider = ({ children }) => {
  
  const [state, dispatch] = useReducer(reducer, defaultState);
  //Next stage
  const handlenext = () => {
    dispatch({ type: "MOVE_TO_FORM", payload: "1" });
    // console.log("some things");
    // if (state.selectedtype === "HOMOGENOUS") {
    //   dispatch({ type: "CASE_TYPE", payload: "HOMOGENOUS" });
    // } else if (state.selectedgln === "HETEROGENOUS") {
    
    //   dispatch({ type: "CASE_TYPE", payload: "HETEROGENOUS" });
    // } 
  };
  //previous stage

  useEffect(() => {
    const getProducts = async () => {
      try {
        const data = (await listedProducts(registrationid)).data;
        // console.log("Data", data.returnedObject.glnInformationList);
        dispatch({
          type: "PRODUCT_INFO",
          payload: data.returnedObject,
        });
      } catch (ex) {
        toast("Something went wrong with product retrival");
      }
    };
    if(registrationid !=undefined){
      getProducts();
    }
      
    
    
  }, []);

  useEffect(() => {
    const ITF14s = async () => {
      try {
        const data = (await getITF14s(registrationid)).data;
        // console.log("Data", data.returnedObject.glnInformationList);
        dispatch({
          type: "POPULATE_ITFS",
          payload: data.returnedObject,
        });
      } catch (ex) {
        toast("Something went wrong with itf retrival");
      }
    };
    if(registrationid !=null &&registrationid !=undefined && registrationid !==""){
      ITF14s();
    }
 
  }, []);


  const handleITFSelection = (id) => {
    dispatch({ type: "HANDLE_ITFTYPESELECTED", payload: id });
  };
  const handleITFItemSelection = (id) => {
    dispatch({ type: "HANDLE_ITFItemSELECTED", payload: id });
  };
  const handleITFLevelSelection = (id) => {
    dispatch({ type: "HANDLE_ITFLevelSELECTED", payload: id });
  };
  const handleChange = (e, fieldname) => {
    dispatch({
      type: "UPDATE_INPUT",
      value: e.target.value,
      name: fieldname,
    });
  };



  return (
    <ITFContext.Provider
      value={{
        ...state,
        dispatch,
        handlenext,       
        handleITFSelection,
        handleITFItemSelection,
        handleITFLevelSelection,    
        handleChange
      }}
    >
      {children}{" "}
    </ITFContext.Provider>
  );
};

export const useITFContext = () => {
  return useContext(ITFContext);
};

export { ITFContext, ITFProvider };
