import React, { useEffect, useState } from "react";

import {
  CCallout,
  CRow,
  CCol,
  CContainer,
  CFormCheck,
  CButton,
  CSpinner,
} from "@coreui/react";
import unitbottle from "./images/unitbottle.png";
import pack from "./images/pack.png";
import pallet from "./images/pallet.png";
import cases_img from "./images/case.png";
import mixed_img from "./images/mixed.png";
import mixed_pallet from "./images/mixedpallet.png";
import { useProductListingContext } from "../../context/productListingContext";

const Step1 = () => {
  const {
    nextPage,
    handlePackaging,
    loadPackaging,
    packaginglevel_id,
    loading,
  } = useProductListingContext();

  // console.log("market value", market);

  const rendercard = () => {
    if (packaginglevel_id === 1) {
      return (
        <CCallout color="primary"> {loadPackaging[0].description} </CCallout>
      );
    } else if (packaginglevel_id === 2) {
      return (
        <CCallout color="secondary"> {loadPackaging[1].description} </CCallout>
      );
    } else if (packaginglevel_id === 3) {
      return (
        <CCallout color="success"> {loadPackaging[2].description} </CCallout>
      );
    } else if (packaginglevel_id === 4) {
      return (
        <CCallout color="warning"> {loadPackaging[3].description} </CCallout>
      );
    } else if (packaginglevel_id === 5) {
      return <CCallout color="info"> {loadPackaging[4].description} </CCallout>;
    } else if (packaginglevel_id === 6) {
      return <CCallout color="dark"> {loadPackaging[5].description} </CCallout>;
    } else if (packaginglevel_id === 7) {
      return (
        <CCallout color="success"> {loadPackaging[6].description} </CCallout>
      );
    }
  };
  const showThisImage = () => {
    if (packaginglevel_id === 1) {
      return (
        <img
          className="gtin-icons"
          src={unitbottle}
          style={{ height: 200 }}
          alt=""
        />
      );
    } else if (packaginglevel_id === 2) {
      return (
        <img className="gtin-icons" src={pack} style={{ height: 200 }} alt="" />
      );
    } else if (packaginglevel_id === 3) {
      return (
        <img
          className="gtin-icons"
          src={cases_img}
          style={{ height: 200 }}
          alt=""
        />
      );
    } else if (packaginglevel_id === 4) {
      return (
        <img
          className="gtin-icons"
          src={mixed_img}
          style={{ height: 200 }}
          alt=""
        />
      );
    } else if (packaginglevel_id === 5) {
      return (
        <img
          className="gtin-icons"
          src={pallet}
          style={{ height: 200 }}
          alt=""
        />
      );
    } else if (packaginglevel_id === 6) {
      return (
        <img
          className="gtin-icons"
          src={mixed_pallet}
          style={{ height: 200 }}
          alt=""
        />
      );
    }
  };
  return (
    <div>
      <CContainer>
        <h3> Packaging Level </h3>{" "}
        {loading && <CSpinner color="warning" variant="grow" />} <hr />
        <p> Top level explanation of packaging level </p> {rendercard()}{" "}
        <CRow>
          <CCol sm={8}>
            {" "}
            {loadPackaging.map((p) => {
              return (
                <CFormCheck
                  key={p.id}
                  type="radio"
                  name="flexRadioDefault"
                  label={p.name}
                  checked={packaginglevel_id == p.id}
                  onChange={() => handlePackaging(p.id)}
                />
              );
            })}{" "}
          </CCol>{" "}
          <CCol sm={4}> {showThisImage()} </CCol>{" "}
        </CRow>{" "}
        {packaginglevel_id > 0 ? (
          <button
            className="bg-gs1-blue text-white h-10 w-36 my-3"
            onClick={nextPage}
          >
            Next <i className="fa fa-arrow-right " />
          </button>
        ) : (
          <p style={{ color: "red" }}> "please select a packaging level" </p>
        )}{" "}
      </CContainer>{" "}
    </div>
  );
};

export default Step1;
