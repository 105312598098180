import { CContainer, CCard, CCardBody, CButton, CCollapse } from "@coreui/react";

import { CSmartTable } from "@coreui/react-pro";
import { useITFContext } from "../../context/itfContext";
import { useState } from "react";
import { toast } from "react-toastify";

const ViewITF14s = () => {
  const {
    itfrecords
  } = useITFContext();
  const [details, setDetails] = useState([]);
  const newArray = itfrecords.map(({ indicator, items, ...itfrecords }) => itfrecords)
  const columns = [
    {
      key: "itF14CODE",
      // isImg: true,
      label: "ITF14 Code",
      filter: true,
      sorter: true,
    },
    {
      key: "description",
      label: "Description",
      filter: true,
      sorter: true,
      
    },
    {
      key: "gtin",
      label: "GTIN",
      filter: true,
    },

    {
      key: "contentCount",
      label: "ContentCount",
      filter: true,
      sorter: true,
    },


    {
      key: "createdAt",
      label: "CreatedAt",
      filter: true,
      sorter: true,
    },
    {
      key: 'show_details',
      label: '',
      _style: { width: '1%' },
      filter: false,
      sorter: false,
    },
  ];

  const generateITF = (itf) => {
    //switch back to axios
    fetch(
      `${process.env.REACT_APP_BASE_URL}ITF14/generateitf?ITF14Code=${itf}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Add any necessary headers for authentication or other requirements
        },
      }
    )
      .then((response) => response.blob()) // Convert the response to a Blob object
      .then((blob) => {
        // Create a temporary URL for the Blob
        const url = URL.createObjectURL(blob);
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = url;
        // if (format == 2) {
          link.setAttribute("download", `${itf}.wmf`);
        // } else {
        //   link.setAttribute("download", `${gtin}.png`);
        // }
        // Set the desired file name and extension
        // Programmatically click the link to trigger the download
        link.click();
        // Clean up the temporary URL and link
        URL.revokeObjectURL(url);
        link.remove();
        // dispatch({ type: "IMAGE_DOWLOADED" });
      })
      .catch((error) => {
        toast.warn("Something went wrong");
      });
  };


  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }
  const getChildren = (item) => {
    let _items = itfrecords.filter(f => f.itF14CODE === item)[0].items;
   let k=_items.map(v=>(<li>{v}</li>))
   return k;
    
 
  }
  return (
    <>
      <div className="border-t-2 mx-3 my-3 border-b-gs1-blue">
        <CCard>
          <CCardBody className="create_gtn-card">
            <div className="overflow-scroll">
              <CSmartTable
                items={newArray}
                columns={columns}
                columnFilter
                columnSorter
                pagination
                tableProps={{
                  striped: true,
                  bordered: false,
                  hover: true,
                }}

                scopedColumns={{
                  show_details: (item) => {
                    return (
                      <td className="py-2">
                        <CButton
                          color="primary"
                          variant="outline"
                          shape="square"
                          size="sm"
                          onClick={() => {
                            toggleDetails(item.itF14CODE)
                          }}
                        >
                          {details.includes(item.itF14CODE) ? 'Hide' : 'Show'}
                        </CButton>
                      </td>
                    )
                  },
                  details: (item) => {
                   
                    return (
                      <CCollapse visible={details.includes(item.itF14CODE)}>
                        <CCardBody className="p-3">
                          <div className="d-flex flex-row">
                            <div className="fw-bold">Indicator Digit:</div>
                            <div className="mx-5">{itfrecords.filter(f => f.itF14CODE === item.itF14CODE)[0].indicator}</div>
                          </div>
                          <div className="fw-bold mt-3">Description:</div>
                          <div className="ml-5">
                            {itfrecords.filter(f => f.itF14CODE === item.itF14CODE)[0].description}
                          </div>
                          <div className="fw-bold mt-3">Content Description:</div>
                          <ul className="ml-5">
                            {
                              
                            getChildren(item.itF14CODE)
                            }
                          </ul>

                          <div className="d-flex justify-content-end">
                          <CButton onClick={()=>generateITF(item.itF14CODE)} size="sm" color="danger" className="ml-1">
                            Download
                          </CButton>
                          </div>
                        </CCardBody>
                      </CCollapse>
                    )
                  },
                }}
              />
            </div>
          </CCardBody>{" "}
        </CCard>{" "}
      </div>{" "}
    </>
  );
};

export default ViewITF14s;
