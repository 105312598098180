const reducer = (state, action) => {

  switch (action.type) {

    case "HANDLE_ITFItemSELECTED":
      return { ...state, itfinfo: action.payload };
      break;

    case "POPULATE_ITFS":

      return { ...state, itfrecords: action.payload };
      break;
    case "HANDLE_ITFTYPESELECTED":
      return { ...state, selectedtype: action.payload };
      break;
    case "HANDLE_ITFLevelSELECTED":
      return { ...state, level: action.payload };
      break;
    case "MOVE_TO_FORM":
      return { ...state, showform: action.payload };
      break;
    case "PRODUCT_INFO":
      return { ...state, products: action.payload };
      break;
    case "PREVIOUS_FORM":
      return { ...state, showform: action.payload };
      break;
    case "MOVE_TO_REVIEW":
      return { ...state, showreview: action.payload };
      break;
    case "UPDATE_INPUT":
      return { ...state, [action.name]: action.value };

  }
};
export default reducer;
