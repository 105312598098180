import React from "react";
import {
  CCallout,
  CRow,
  CCol,
  CContainer,
  CFormCheck,
  CButton,
  CInputGroup,
  CInputGroupText,
  CFormInput,
} from "@coreui/react";
import { AiFillDelete } from "react-icons/ai";

import studio from "../barcodecomponent/images/photostudio.png";
import { useProductListingContext } from "../../context/productListingContext";
import defaultimg from "./images/noimage.png";
import { useState } from "react";

const Step3 = ({}) => {
  const {
    nextPage,
    previousPage,
    dispatch,
    FrontImage,
    BackImage,
    dispImg1,
    dispImg2,
  } = useProductListingContext();
  const [show, setShow] = useState({ showImg: defaultimg, frnt: "", bck: "" });
  //Save product image ...................
  const saveFrontImage = (e, fieldname) => {
    const [file] = e.target.files;
    const getUrl = e.target.files[0];
    const getUrlshw = URL.createObjectURL(file);
    // setImg(URL.createObjectURL(file));
    dispatch({
      type: "IMAGE_GET",
      payload: { getUrl, getUrlshw },
      // value: getUrl,
      // name: fieldname,
    });
    setShow({ ...show, frnt: getUrlshw });
  };
  //remove front image.......
  const removeFrontImage = (e, fieldname) => {
    const getUrl = "";
    dispatch({
      type: "IMAGE_GET",
      payload: { getUrl: "", dispImg1 },
      // value: getUrl,
      // name: fieldname,
    });
    setShow({ ...show, frnt: dispImg1 });
  };
  const removeBackImage = (e, fieldname) => {
    console.log("momo");
    const getUrl = "";

    dispatch({
      type: "BACKIMAGE_GET",
      payload: { value: "", dispImg1 },
    });
    setShow({ ...show, bck: dispImg2 });
  };

  const saveBackImg = (e, fieldname) => {
    const [file] = e.target.files;
    const getUrlshw = URL.createObjectURL(file);
    const getUrl = e.target.files[0];
    // console.log(e.target.files[0])

    // setImg(URL.createObjectURL(file));
    dispatch({
      type: "BACKIMAGE_GET",
      payload: { getUrl, getUrlshw },
      // value: { a: getUrl, b: getUrlshw },
      // name: fieldname,
    });
    setShow({ ...show, bck: getUrlshw });
  };

  return (
    <>
      <h3> Product Image(Basic Listing) </h3> <hr />
      <p> Explanation of product images(size and all) </p>{" "}
      <CRow>
        <CCol>
          <div>
            {" "}
            {show.frnt !== "" ? (
              <AiFillDelete size={20} color="red" onClick={removeFrontImage} />
            ) : (
              ""
            )}{" "}
          </div>{" "}
          <br />
          <img
            src={show.frnt ? show.frnt : dispImg1 ? dispImg1 : show.showImg}
            style={{ height: 270, width: 270, objectFit: "cover" }}
            alt=""
          />
          <CInputGroup className="mb-3">
            <CFormInput
              type="file"
              id="inputGroupFile01"
              accept="image/*"
              name="FrontImage"
              onChange={(e) => saveFrontImage(e, "FrontImage")}
            />{" "}
          </CInputGroup>{" "}
        </CCol>{" "}
        <CCol>
          <div>
            <div>
              {" "}
              {show.bck !== "" ? (
                <AiFillDelete size={20} color="red" onClick={removeBackImage} />
              ) : (
                ""
              )}{" "}
            </div>{" "}
          </div>{" "}
          <br />
          <img
            src={show.bck ? show.bck : dispImg2 ? dispImg2 : show.showImg}
            style={{ height: 270, width: 270, objectFit: "cover" }}
            alt=""
          />
          <CInputGroup className="mb-3">
            <CFormInput
              type="file"
              id="inputGroupFile01"
              accept="image/*"
              name="BackImage"
              onChange={(e) => saveBackImg(e, "BackImage")}
            />{" "}
          </CInputGroup>{" "}
        </CCol>{" "}
        <CCol>
          <img
            className="productImage"
            src={studio}
            style={{ height: 270 }}
            alt=""
          />
          <p> email us at: enquiries @gs1ng.org </p>{" "}
        </CCol>
        <CCol xs={12}>
          <button
            className="bg-gs1-blue text-white h-10 w-36 my-3"
            onClick={previousPage}
            shape="rounded-pill"
          >
            <i className=" fa fa-arrow-left " /> Previous page{" "}
          </button>{" "}
          <button
            onClick={nextPage}
            className="bg-gs1-color-orange text-white h-10 w-36 my-3"
          >
            Next page {""} <i className=" fa fa-arrow-right " />
          </button>{" "}
        </CCol>{" "}
      </CRow>{" "}
    </>
  );
};

export default Step3;
