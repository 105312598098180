import { initial } from "lodash";
import React, { useContext, useReducer, useEffect } from "react";
import reducer from "../reducers/productListingReducer";
import { toast } from "react-toastify";
import {
  getBrandDetails,
  getPackagingLevel,
  getPackagingType,
  getunitOfMeasure,
  getTargetmarket,
  getBrickCategory,
  listedProducts,
  getGTinFeedetails,
  generateBarcode,
} from "../services/generalService";
import {
  NEXT_STAGE,
  PREVIOUS_STAGE,
  HANDLE_PACKAGING,
  LISTED_BRANDS,
  PACKAGING_TYPES,
  UPDATE_INPUT,
  UPDATE_MARKET,
  PACKAGING_LEVEL,
  ERROR_FOUND,
} from "../utils/action";

const defaultState = {
  packaginglevel_id: "",
  toggleHealthCare: false,
  ActiveIngredient: "",
  level: 1,
  selectedmarket: [],
  visibleM: false,
  targetedMarket: [],
  bricks: [],
  dispImg1: "",
  dispImg2: "",
  packagingLevel: 0,
  thisGtin: "1234567890128",
  loadPackaging: [],
  loadPackageType: [],
  loading: false,
  productdescription: "",
  consumerfirstavailabilitydate: "",
  packagingtype_id: 1,
  marketingmessage: "",
  TargetMarketList: "",
  selectedPackage: "",
  brands: [],
  brickcategory_id: "",
  brandinformation_id: "--select--",
  BackImage: "",
  sideOneImg: "",
  sideTwoImage: "",
  sideThreeImg: "",
  FrontImage: "",
  error: false,
  netcontent_id: "",
  netweight: "",
  lifespan: "",
  lifespanunit: "",
  ingredients: "",
  storageinstruction: "",
  directionofuse: "",
  nafdacnumber: "",
  units: [],
  functionalName: "",
  genericName: "",
  tradeItemDescription: "",
  manufacturer:"",
  manufacturerAddress:"",
  strength: "",
  productForm: "",
  packunit: "",
  atcCode: "",
  routeOfAdministration: "",
  globalProductCategory: "",
  packSize: "",
  quantity: "",
  TargetMarketList: [],
  directionOfUse: "",
  primaryPackaginMaterial: "",
  completedHcInfo: "",
  loading: false,
  showBrand: [],
  postProduct: false,
  directionOfUse: "",
  products: [],
  productsLoader: false,
  gtinFees: [],
  shelfLife: "",
  packunit: "",
  additionalCost: 0,
  additionalRange: 0,
  totalcost: 0,
  showBarcode: "",
  downloadBarcode: "",
  visibleM: false,
  gtinfetch: "",
  updateFlag: false,
};
const getStoredItems = () => {
  const a = sessionStorage.getItem("company");
  return a ? JSON.parse(a) : {};
};

const { registrationid } = getStoredItems();

// console.log("my reg shit", registrationid);
const AppContext = React.createContext();
const AppProvider = ({ children }) => {
  // const [producListing, setProductListing] = useState(listing);
  const [state, dispatch] = useReducer(reducer, defaultState);
  //Next stage
  const nextPage = () => {
    dispatch({ type: NEXT_STAGE });
    // console.log("hello", state.step);
  };
  //previous stage
  const previousPage = () => {
    dispatch({ type: PREVIOUS_STAGE });
  };
  // const saveProductImg1 = () => {};

  const handlePackaging = (id) => {
    dispatch({ type: HANDLE_PACKAGING, payload: id });
  };

  const handleChange = (e, fieldname) => {
    // console.log("e", e);
    dispatch({
      type: UPDATE_INPUT,
      value: e.target.value,
      name: fieldname,
    });
  };

  const handleTargetMarket = (e, det) => {
    // console.log("de", e);
    var a = Array.isArray(e) ? e.map((x) => x.value) : [""];
    dispatch({
      type: UPDATE_MARKET,
      value: a,
      name: det,
    });
  };

  const handleChangeB = (e) => {
    // console.log("lol", e);
    const value = e.value;
    // console.log("my val", value);
    dispatch({ type: "SAVE_BRICK", payload: value });
  };
  const handleSelect = (e, s) => {
    if (s === "brickcategory_id") {
      console.log(s, e);
      handleChangeB(e);
    } else if (s === "TargetMarketList") {
      handleTargetMarket(e, s);
    }
  };

  const handlePackageChange = (id) => {
    // console.log("called", id);
  };

  useEffect(() => {
    const getUnits = async () => {
      try {
        const data = (await getunitOfMeasure()).data;
        dispatch({ type: "UNITS", payload: data.returnedObject });
      } catch (ex) {
        toast("Something went wrong with units of measurement");
      }
    };
    getUnits();
  }, []);

  useEffect(() => {
    const getThePackagingLevel = async () => {
      try {
        dispatch({ type: "LOADING" });
        const data = (await getPackagingLevel()).data;
        // console.log("packaging level", data);
        dispatch({ type: PACKAGING_LEVEL, payload: data.returnedObject });
      } catch (er) {
        dispatch({ type: ERROR_FOUND });
        toast.warning("I think your network is bad");
      }
    };
    getThePackagingLevel();
  }, []);

  useEffect(() => {
    const getBricks = async () => {
      console.log("fetching bricks ...................")
      try {
        const data = (await getBrickCategory()).data;
        dispatch({ type: "BRICKS", payload: data.returnedObject });
      } catch (ex) {
        toast.warn("Something is wrong ");
      }
      // console.log("This guys has been called");
    };
    getBricks();
  }, []);

  useEffect(() => {
    const getThePackagingType = async () => {
      try {
        const data = (await getPackagingType()).data;
        // console.log("packaging types ", data);
        dispatch({ type: PACKAGING_TYPES, payload: data.returnedObject });
      } catch (error) {}
    };
    getThePackagingType();
  }, []);

  useEffect(() => {
    const getTheBrands = async () => {
      try {
        const data = (await getBrandDetails(registrationid)).data;
        // console.log(data);
        dispatch({ type: LISTED_BRANDS, payload: data.returnedObject });
      } catch (error) {}
    };
    if(registrationid !=undefined){
      getTheBrands();
    }
  }, [state.loading, state.updateFlag]);

  useEffect(() => {
    const myListedProducts = async () => {
      try {
        dispatch({ type: "LOADING_PRODUCT" });
        const data = (await listedProducts(registrationid)).data;
        // console.log("Data on listed products", data);
        dispatch({ type: "PRODUCT_LOADED", payload: data.returnedObject });
      } catch (error) {}
    };
    if(registrationid !=undefined){
      myListedProducts();
    }
  }, [state.postProduct]);

  useEffect(() => {
    const getTagretmarket = async () => {
      try {
        // dispatch({ type: "LOADING_TARGETMARKET" });
        const data = (await getTargetmarket()).data;
        // console.log("targetMarket list", data);
        dispatch({ type: "MARKET_LIST", payload: data.returnedObject });
      } catch (error) {}
    };
    getTagretmarket();
  }, []);

  useEffect(() => {
    const getDetailsForAdditional = async () => {
      try {
        const data = (await getGTinFeedetails()).data;
        // console.log("fee details", data);
        dispatch({ type: "GTIN_INFO", payload: data.returnedObject });
      } catch (er) {
        toast.warning("I think your network is bad");
      }
    };
    getDetailsForAdditional();
  }, []);

  // useEffect(() => {

  //   // getImages();
  // }, []);

  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
        nextPage,
        previousPage,
        handlePackaging,
        handlePackageChange,
        handleChange,
        handleTargetMarket,
        handleSelect,
      }}
    >
      {" "}
      {children}{" "}
    </AppContext.Provider>
  );
};

export const useProductListingContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
