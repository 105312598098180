
export const Links = [{
    id: 1,
    text: "Home",
    url: "/home"
},
{
    id: 2,
    text: "Gtin Management",
    url: "/gtinManagement"
},
{
    id: 3,
    text: "Logistics",
    url: ""

},
{
    id: 4,
    text: "Product Directory",
    url: ""
}
]


