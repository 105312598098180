export const packaging = [{
    _id: "01",
    name: "Rubber",
    value: "Rubber"


},
{
    _id: "02",
    name: "Papper",
    value: "Papper"

},
{
    _id: "03",
    name: "Plastic",
    value: "Plastic"

},
{
    _id: "04",
    name: "Nylon",
    value: "Nylon"

},
]


export const productCategory = [{
    _id: "01",
    name: "Food/Eatable",
    value: "Food/Eatable"
},
{
    _id: "02",
    name: "Coolant/Water/Drinkable",
    value: "Coolant/Water/Drinkable"

},
{
    _id: "03",
    name: "Footwear/Shoes/etc",
    value: "Footwear/Shoes/etc"

},
]

export const stateOptions = [{
    value: 0,
    text: "NG",
    name: "NG"

},
{
    value: 1,
    text: "ARG",
    name: "ARG"
},
{
    value: 2,
    text: "AMR",
    name: "AMR",
},
{
    value: 3,
    text: "JPN",
    name: "JPN",
},
{
    value: 4,
    text: "CHN",
    name: "CHN",
},
];
export const measurement = [{

    _id: 0,
    text: "Kilogram",
    name: "KG"

},
{
    _id: 1,
    text: "Gram",
    name: "G"
},
{
    _id: 2,
    text: "Kilo",
    name: "KIL",
},


];
export const dayMonth = [{

    id: "weeks",
    value: "weeks",
    name: "Weeks",
    text: "Weeks",
    label: "Weeks"

},
{
    id: "Days",
    value: "Days",
    name: "Days",
    text: "Days",
    label: "Days"
},
{
    id: "Month(s)",
    value: "Month(s)",
    name: "Month(s)",
    text: "Months(s)",
    label: "Months(s)"
},
{
    id: "Year(s)",
    value: "Year(s)",
    name: "Year(s)",
    text: "Year(s)",
    label: "Year(s)"
},


];

// export function getPackaging() {
//     return packaging;
// }
// export function productCategories() {
//     return productCategory;
// }
// export function getOptions() {
//     return options;
// }
// export function getmeasurement() {
//     return measurement;
// }
// export function getDayMonth() {
//     return dayMonth;
// }