const reducer = (state, action) => {
  const { level } = state;
  switch (action.type) {
    case "HANDLE_GLNSELECTED":
      return { ...state, selectedgln: action.payload };
      break;
    case "NEXT_STAGE":
      return { ...state, level: action.payload };
    case "PREVIOUS_STAGE":
      return { ...state, level: level - 1 };
    case "PREVIOUS_FORM":
      return { ...state, level: action.payload };
    case "BACK_TO_FUNCTION":
      return { ...state, level: action.payload };
    case "FINAL_STAGE":
      return { ...state, level: action.payload };
    case "SET_GLNS":
      return { ...state, glns: action.payload };
    case "SET_Countries":
      return { ...state, countries: action.payload };
    case "SET_States":
      return { ...state, states: action.payload };
    case "SET_SELECTED_COUNTRY":
      return { ...state, selectedCountry: action.payload };
    case "SET_SELECTED_STATE":
      return { ...state, selectedState: action.payload };
    case "SET_COUNTRY_FOCUS":
      return { ...state, countryHasFocus: action.payload };
    case "SET_STATE_FOCUS":
      return { ...state, stateHasFocus: action.payload };
    case "SET_CITY_FOCUS":
      return { ...state, cityHasFocus: action.payload };
    case "SET_STREET_FOCUS":
      return { ...state, streetHasFocus: action.payload };
    case "SET_FETCH_COORD":
      return { ...state, fetchingCoord: action.payload };

    case "UPDATE_INPUT":
      return { ...state, [action.name]: action.value };
    // case "NEXT_STAGE":
    //   return { ...state, level: level - 1 };
  }
};
export default reducer;
