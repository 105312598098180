import React, { useContext, useReducer, useEffect } from "react";
import userReducer from "../reducers/userReducer";
import { getDashBoardInfo, getExtraDashBoardInfo } from "../services/generalService";

const getStoredItems = () => {
  const a = sessionStorage.getItem("company");
  return a ? JSON.parse(a) : {};
};

const { companyname, email, registrationid, gtinRequested, gtinListed } =
  getStoredItems();

const defaultState = {
  loginLoader: false,
  cacnumber: "",
  companyaddress: "",
  companyname: companyname,
  email: email,
  factoryaddress: "",
  instagramaccount: "",
  isregistrationcompleted: "",
  numberofstaff: "",
  phonenumber: "",
  registrationdate: "",
  twitteraccount: "",
  websiteaddress: "",
  registrationid: registrationid,
  totalGtinsRequested: 0,
  usedGtins: 0,
  remainingGtins: 0,
  totalImagesRequested: 0,
  usedImages: 0,
  remainingImages: 0,
  nextRenewalAmount: 0,
  imageDownloaded: "",
  pendingImageRequest: 0,
  menustatus: true,
  totalUsed:0,
  totalRemaining:0

  // access: access,
};

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, defaultState);
  let currentPayload=null;

  useEffect(() => {
    const dashBoardDetails = async () => {
      try {
        const data = (await getDashBoardInfo(registrationid)).data;
        // console.log("my regggg", registrationid);
        // console.log("data shit", data);
        currentPayload=data.returnedObject;
        dispatch({ type: "DASHBOARD_DATA", payload: data.returnedObject });
      } catch (er) { }
    };
    if(registrationid !=undefined){
      dashBoardDetails();
    }
  }, [state.usedGtins, state.imageDownloaded]);
  useEffect(() => {
    const dashBoardExtraDetails = async () => {
      try {
        const data = (await getExtraDashBoardInfo()).data;
        currentPayload['totalUsed']= data.returnedObject['glnDashboardInfo']['totalUsed'];
        currentPayload['totalRemaining']= data.returnedObject['glnDashboardInfo']['totalRemaining'];
        // console.log("data extra db", currentPayload);
        dispatch({ type: "DASHBOARD_DATA", payload: currentPayload });
      } catch (er) { }
    };
    dashBoardExtraDetails();
  }, [state.usedGtins, state.imageDownloaded]);

  return (
    <UserContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}{" "}
    </UserContext.Provider>
  );
};
export const useUserContext = () => {
  return useContext(UserContext);
};
export { UserContext, UserProvider };
