import React, { useState, Component } from "react";
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CCard,
  CCardBody,
  CContainer,
} from "@coreui/react";
import { useGLNContext } from "../context/glnContext";
import GlnTypes from "./glncomponents/selectGLN";
import FunctionGln from "./glncomponents/function";
import ShowDetailS from "./barcodecomponent/reviewDet";
import ShowGLNDetails from "./glncomponents/showDetails";
import Mobile from "./glncomponents/mobile";
import ShowGLNDetailsMobile from "./glncomponents/showmobile";
import DigitalGLN from "./glncomponents/Digital";
import ShowGLNDetailsDigital from "./glncomponents/showDigital";
import ITF14Form from './glncomponents/itf14form';
import ShowPhysical from "./glncomponents/showDetailsPhysical";
import { useITFContext } from "../context/itfContext";
import ITFTypes from "./glncomponents/selectITFType";

const CreateITF14 = () => {
  const { selectedtype,showform } = useITFContext();
  // console.log("hiii", glnType);
  const renderForm = () => {
    switch (selectedtype) {
      case null||undefined||"":
        return <ITFTypes />;
      case "HOMOGENOUS":
        return <ITFTypes />;
      case "HETEROGENOUS":
        return <ITFTypes />;      
   
    }
   
  };

  return (
    <>
      <div className="border-t-2 border-gs1-blue mx-3 my-3">
        <CCard>
          <CCardBody className="create_gtn-card"> {renderForm()} </CCardBody>{" "}
        </CCard>{" "}
      </div>{" "}
    </>
  );
};

export default CreateITF14;
