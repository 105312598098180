import logo from "./logo.svg";
import "./App.css";
import Nav from "./components/nav";
import { CRow, CCol, CModal, CModalFooter, CModalHeader, CModalBody, CModalTitle, CButton, CFormTextarea, CFormCheck } from "@coreui/react";
import SideNav from "./components/sideNav";
import AdditionalBarcodes from "./components/additionalBarcodes";
import ImageRequest from "./components/barcodeImage";
import { Route, Routes } from "react-router-dom";

import Logistics from "./components/logistics";
import Brands from "./components/brands";

import { useProductListingContext } from "./context/productListingContext";
import CreateGtn from "./components/barcodecomponent/creategtin";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import ProtectedRoute from "./components/protectedRoute";
import { access, getCurrentUser } from "./services/generalService";
import { GtinManagement, Login, Home } from "./pages";

import ViewProducts from "./components/viewProducts";

import ViewGLNs from "./components/glncomponents/viewGlns";
import ViewITF14s from "./components/glncomponents/viewITF14s";
import { fetchJwt } from "./services/http";
import UploadProducts from "./components/uploadProducts";
import Layout from "./Layout";
import Sidebar from "./components/sideBar";
import CreateGLN from "./components/creategln";
import CreateITF14 from "./components/createitf14";
import ChangeMypass from "./components/passChange";
import UserLayout from "./components/userLayout";
import BarcodeImages from "./components/barcodeImage";
import ProductUpload from "./components/productupload";
import { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import axios from "axios";

function App({ }) {
  const [hasAttested, setHasAttested] = useState(null)
  const [loadingNow, setLoadingNow] = useState(false)
  const [disagree, setDisagree] = useState(null)
  const [visible, setVisible] = useState(false)
  const [reason, setReason] = useState("")

  useEffect(() => {
    const checkAttestation = async () => {
      try {
        // console.log(data_to_send);
        await axios({
          url: `https://membershipservices.gs1ng.org/api/v2/member/vbg-permission/isset`,
          method: "GET",
          headers: {
            ContentType: "multipart/form-data",
          }
        })
          .then((res) => {
            if (res.data.isSuccess == true) {
              console.log(res.data.message)
              setHasAttested(true)
              setVisible(false)
  
            } else {
              setHasAttested(false)
              setVisible(true)
            }
  
          })
          .catch((er) => {
           
  
  
          });
      } catch (err) {
       
  
      }
    };
  
    checkAttestation();
  }, []);

  const user = getCurrentUser();
  const ShowSideNav = () => {
    if (!getCurrentUser()) {
      return <> </>;
    } else return <Sidebar />;
  };

  const ShowNav = () => {
    if (!getCurrentUser()) {
      return <> </>;
    } else return <Nav user={user} />;
  };
  const sendAttestation = async () => {
    let agree = !disagree;
    let disagreereason = reason
    let data_to_send = {
      "comment": disagreereason,
      "permissionStatus": agree
    }

    try {
      // console.log(data_to_send);
      await axios({
        url: `https://membershipservices.gs1ng.org/api/v2/member/vbg-permission/set/post`,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: data_to_send,
      })
        .then((res) => {
          if (res.data.isSuccess == true) {
            console.log(res.data.message)
            setVisible(false)

            toast.success("Submitted Successfully");


          } else {
            setVisible(false)
            toast.warn("Something went wrong, contact GS1 Nigeria");


          }

        })
        .catch((er) => {
          setVisible(false)
          toast.warn("Something went wrong, contact GS1 Nigeria");


        });
    } catch (err) {
      setVisible(false)
      toast.error("Please retry");

    }

  }
  const showmodal = () => {
    return (

      <CModal
        visible={!hasAttested && visible}
        onClose={() => setVisible(false)}

      >
        <CModalHeader >
          <CModalTitle className="text-gs1orange fw-bold text-center">Global Registry Platform Data Protection Policy </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>
            As consented to by members in the Terms and Conditions of GS1 Nigeria registration requirements.
          </p>
          <p>You are responsible for maintaining the accuracy of your Products data and Products listed on the GS1 Nigeria OneSource will be accessible in the Global Registry Platform  <a href="https://gs1vbgservices.gs1ng.org">Verifidbygs1service (gs1ng.org).</a>
          </p>

          <div>
            <p><span className="text-gs1orange fw-bold">NOTE:</span> GS1 Global Registry gives the same information on your product pack which is publicly accessible. Verified By GS1 is a verification tool for authentication beyond your local market. </p>
          </div>

          <div>
            {disagree == true &&
              <CFormTextarea
                onChange={(e) => setReason(e.target.value)}
                id="floatingTextarea"

                placeholder="Please, leave reason for your objection"
              ></CFormTextarea>
            }
          </div>
          <div className="d-flex mt-5 justify-content-end">
            <CFormCheck size={20} onClick={(e) => setDisagree(false)} className="fw-bold text-gs1-color-blue" inline type="radio" name="attest" id="inlineCheckbox1" value="option1" label="Agree" />
            <CFormCheck onClick={(e) => setDisagree(true)} className="fw-bold text-gs1-color-orange" inline type="radio" name="attest" id="inlineCheckbox2" value="option2" label="Disagree" />
          </div>

        </CModalBody>
        <CModalFooter>
          <button
            className="h-[36px] w-[58px] px-1 text-[14px] bg-gs1-color-orange text-gs1-color-white"
            color="secondary"
            onClick={(e) => setVisible(false)}
          >
            Close
          </button>
          <button
            disabled={loadingNow}
            className="h-[36px] text-[14px] px-1 bg-gs1-blue w-[140px] text-gs1-color-white"
            onClick={sendAttestation}
            color="primary"
          >
            Submit {""}
            <span>
              {loadingNow && (
                <HashLoader className="ml-3" color="#ffffff" size={22} />
              )}
            </span>
          </button>

        </CModalFooter>

      </CModal>
    )
  }

  return (
    <>
      <ToastContainer />
      {window.location.pathname == '/user/home' && showmodal()}
      <Routes>
        <Route path="/login" element={<Login />} />{" "}
        <Route path="/" element={<Login />} />{" "}
        <Route path="/user" element={<UserLayout />}>
          <Route index path="/user/Home" element={<Home />} />{" "}
          <Route path="/user/managebrands" element={<Brands />} />{" "}
          <Route path="/user/creategtin" element={<CreateGtn />} />{" "}
          <Route path="/user/viewproducts" element={<ViewProducts />} />{" "}
          <Route path="/user/settings" element={<ChangeMypass />} />{" "}
          <Route path="/user/additionals" element={<AdditionalBarcodes />} />{" "}
          <Route path="/user/buybarcodeimage" element={<BarcodeImages />} />{" "}
          <Route path="/user/productupload" element={<ProductUpload />} />{" "}
          <Route path="/user/viewglns" element={<ViewGLNs />} />{" "}
          <Route path="/user/listglns" element={<CreateGLN />} />{" "}
          <Route path="/user/listitf14" element={<CreateITF14 />} />{" "}
          <Route path="/user/viewitf14s" element={<ViewITF14s />} />{" "}
          <Route path="/user/productupload" element={<ProductUpload />} />{" "}
          {/* <Route path="/user/products" element={<Ad />} />{" "} */}
        </Route>{" "}
      </Routes>{" "}

    </>
  );
}

export default App;
