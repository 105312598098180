import {
  NEXT_STAGE,
  PREVIOUS_STAGE,
  HANDLE_PACKAGING,
  LISTED_BRANDS,
  PACKAGING_TYPES,
  UPDATE_INPUT,
  UPDATE_MARKET,
  PACKAGING_LEVEL,
  ERROR_FOUND,
} from "../utils/action";
const reducer = (state, action) => {
  const { level } = state;
  switch (action.type) {
    case NEXT_STAGE:
      return { ...state, level: level + 1 };
      break;
    case PREVIOUS_STAGE:
      return { ...state, level: level - 1 };
      break;
    case PACKAGING_LEVEL:
      return { ...state, loadPackaging: action.payload, loading: false };
    case PACKAGING_TYPES:
      return { ...state, loadPackageType: action.payload };
    case "MARKET_LIST":
      return { ...state, targetedMarket: action.payload };
    case "BRICKS":
      return { ...state, bricks: action.payload };
    case "UNITS":
      return { ...state, units: action.payload };
    case "SAVE_PRODUCTS":
      return { ...state, postProduct: true };
    case "SAVED_OR_WHATEVER":
      return { ...state, postProduct: false };
    case "AFTER_SAVING_OR_ERROR":
      return { ...state, postProduct: false };
    case "CLEAR":
      return { state: "" };
    case "SHOW_MARKET":
      return { ...state, selectedmarket: action.payload };

    case "SHOW_PACKAGE":
      return { ...state, selectedPackage: action.payload };
    case "SHOW_BRAND":
      return { ...state, showBrand: action.payload };

    case "BRAND_UPDATED":
      return { ...state, updateFlag: !state.updateFlag };

    case HANDLE_PACKAGING:
      return { ...state, packaginglevel_id: action.payload };

    case UPDATE_INPUT:
      return { ...state, [action.name]: action.value };

    case "IMAGE_GET":
      return {
        ...state,
        FrontImage: action.payload.getUrl,
        dispImg1: action.payload.getUrlshw,
      };
    case "SAVE_BRICK":
      return { ...state, brickcategory_id: action.payload };
    case "BACKIMAGE_GET":
      return {
        ...state,
        BackImage: action.payload.getUrl,
        dispImg2: action.payload.getUrlshw,
      };
    case "LOADING":
      return { ...state, loading: true };

    case "CLEAR_STATE":
      return {
        ...state,
        packaginglevel_id: "",
        // toggleHealthCare: false,
        ActiveIngredient: "",
        // level: 1,
        dispImg1: "",
        dispImg2: "",
        // packagingLevel: 0,
        productdescription: "",
        consumerfirstavailabilitydate: "",
        // packagingtype_id: 1,
        marketingmessage: "",
        TargetMarketList: "",
        selectedPackage: "",
        brickcategory_id: "",
        // brandinformation_id: "--select--",
        BackImage: "",
        sideOneImg: "",
        sideTwoImage: "",
        sideThreeImg: "",
        FrontImage: "",
        netcontent_id: "",
        netweight: "",
        lifespan: "",
        lifespanunit: "",
        ingredients: "",
        storageinstruction: "",
        directionofuse: "",
        nafdacnumber: "",
        functionalName: "",
        genericName: "",
        tradeItemDescription: "",
        strength: "",
        productForm: "",
        packunit: "",
        atcCode: "",
        routeOfAdministration: "",
        globalProductCategory: "",
        packSize: "",
        quantity: "",
        // TargetMarketList: [],
        directionOfUse: "",
        primaryPackaginMaterial: "",
        completedHcInfo: "",
        // loading: false,
        directionOfUse: "",
        shelfLife: "",
        packunit: "",
        showBarcode: "",
        downloadBarcode: "",
        gtinfetch: "",
      };
    case UPDATE_MARKET:
      return { ...state, TargetMarketList: action.value };
    case LISTED_BRANDS:
      return { ...state, brands: action.payload };

    case ERROR_FOUND:
      return { ...state, loading: false };
    case "BRAND_SAVE":
      return { ...state, loading: true };
    case "OPEN_GEN":
      return { ...state, visibleM: true };
    // case "CLOSE_MODAL":
    //   return { ...state, visibleM: false, thisGtin: "1234567890128" };
    case "BRAND_SAVED":
      return { ...state, loading: false };
    case "ERROR_SAVING_BRAND":
      return { ...state, loading: false };
    case "LOADING_PRODUCT":
      return { ...state, productsLoader: true };
    case "PRODUCT_LOADED":
      return { ...state, products: action.payload, productsLoader: false };
    case "GTIN_INFO":
      return { ...state, gtinFees: action.payload };
    case "LIST_AS_HEALTHCARE":
      return { ...state, toggleHealthCare: !state.toggleHealthCare };
    case "AMOUNT_TO_PAY":
      return { ...state, additionalCost: action.payload };
    case "BARCODE_IMAGE":
      const { mainImageLink, displayImageLink, gtin } = action.payload;
      return {
        ...state,
        showBarcode: displayImageLink,
        downloadBarcode: mainImageLink,
        gtinfetch: gtin,
        // visibleM: true,
      };
    case "ADDITIONAL_RANGE":
      return { ...state, additionalRange: action.payload };
    case "TOTAL":
    // return {total:action.payload}

    default:
      return state;
      break;
  }
};

export default reducer;