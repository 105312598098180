import React, { useState, Component } from "react";
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CCard,
  CCardBody,
  CContainer,
} from "@coreui/react";
import { useGLNContext } from "../context/glnContext";
import GlnTypes from "./glncomponents/selectGLN";
import FunctionGln from "./glncomponents/function";
import ShowDetailS from "./barcodecomponent/reviewDet";
import ShowGLNDetails from "./glncomponents/showDetails";
import Mobile from "./glncomponents/mobile";
import ShowGLNDetailsMobile from "./glncomponents/showmobile";
import DigitalGLN from "./glncomponents/Digital";
import ShowGLNDetailsDigital from "./glncomponents/showDigital";
import PhysicalGLN from "./glncomponents/physical";
import ShowPhysical from "./glncomponents/showDetailsPhysical";

const CreateGLN = () => {
  const { glnType, level } = useGLNContext();
  // console.log("hiii", glnType);
  const renderForm = () => {
    switch (level) {
      case 0:
        return <GlnTypes />;

      case 1:
        return <FunctionGln />;
        break;
      case 2:
        return <Mobile />;
        break;
      case 3:
        return <DigitalGLN />;
        break;
      case 4:
        return <PhysicalGLN />;
        break;

      // case 3:
      //   return <Step3 />;

      // case 4:
      //   return <Step4 />;

      case 5:
        return <ShowGLNDetails />;
        break;
      case 6:
        return <ShowGLNDetailsMobile />;
        break;
      case 7:
        return <ShowGLNDetailsDigital />;
      case 8:
        return <ShowPhysical />;
        break;
    }
  };

  return (
    <>
      <div className="border-t-2 border-gs1-blue mx-3 my-3">
        <CCard>
          <CCardBody className="create_gtn-card"> {renderForm()} </CCardBody>{" "}
        </CCard>{" "}
      </div>{" "}
    </>
  );
};

export default CreateGLN;
