import React from "react";
import { CFormSelect, CFormLabel } from "@coreui/react";

const Select = ({ name, label, options, ...rest }) => {
  return (
    <>
      <CFormLabel htmlFor="">{label}</CFormLabel>
      <CFormSelect id="inputState" name={name} {...rest}>
        <option>Choose category</option>
        {options.map((c) => (
          <option key={c.id} value={c.id}>
            {c.name}
          </option>
        ))}
      </CFormSelect>
    </>
  );
};

export default Select;
