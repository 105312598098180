import {
  CContainer,
  CRow,
  CCol,
  CForm,
  CButton,
  CFormInput,
  CFormLabel,
  CFormCheck,
  CFormSelect,
  CFormSwitch,
  CTooltip,
} from "@coreui/react";

import Select from "react-select";
// import { CDatePicker } from "@coreui/react-pro@next";
import React, { useState, useEffect } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { useGLNContext } from "../../context/glnContext";

// import { options } from "joi-browser";
// import { set } from "lodash";
// import { forEach } from "lodash";

const DigitalGLN = () => {
  const {
    openningDate,
    handleChange,
    longitude,
    functionName,
    latitude,
    functionDescription,
    IPAddress,
    previousPage,
    affiliation,
    affiliationTo,
    dispatch,
    URL,
    PortNumber,
    PhoneNumber,
    Email,
  } = useGLNContext();
  // console.log("opening", openningDate);

  const preview = () => {
    if (
     
      URL == "" ||
      Email == "" ||
      PhoneNumber == "" ||
      PortNumber == "" ||
      IPAddress == ""
    ) {
      return (
        <button className="h-10 text-white bg-gs1-blue w-52" disabled>
          Please fill in all the fields{" "}
        </button>
      );
    } else {
      return (
        <button
          className="h-10 text-white bg-gs1-blue w-36"
          onClick={() => dispatch({ type: "FINAL_STAGE", payload: 7 })}
        >
          Review <i className="fa fa-arrow-right " />
        </button>
      );
    }
  };

  return (
    <>
      <h3> Digital GLN Location </h3>{" "}
      <p style={{ color: "red" }}>
        {" "}
        * please note that all field are mandetory *{" "}
      </p>{" "}
      <hr />
      <CForm
        className="row g-3"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {/* <CCol md={4}>
        <div className="d-flex justify-content-between">
            <CFormLabel>Opening Days:          
            </CFormLabel>
            <CTooltip
                  trigger={"hover"}
                  placement="top"
                  content="The days of the week that this location open for operation. e.g Monday-Friday"
                >
                  <span>
                    <AiFillInfoCircle color="pupple" size="20" />
                  </span>
                </CTooltip>
          </div>
          <CFormInput
            name="openningDate"
            defaultValue={openningDate}
            type="text"
            onChange={(e) => handleChange(e, "openningDate")}
          />{" "}
        </CCol>{" "} */}
        {/* <CCol md={4}>
          <CFormLabel> Affiliation To: </CFormLabel>{" "}
          <CFormInput
            name=""
            defaultValue={affiliationTo}
            type="text"
            onChange={(e) => handleChange(e, "affiliationTo")}
          />{" "}
        </CCol>{" "} */}
        <CCol md={4}>
        <div className="d-flex justify-content-between">
          <CFormLabel>Affiliation:</CFormLabel>
           <CTooltip
                  trigger={"hover"}
                  placement="top"
                  content="Corporate
                  Social
                  Responsibility
                  Affiliation. A URI value (website address) linking to
                  information related to social
                  responsibility activities and
                  associations that a location is
                  involved with."
                >
                  <span>
                    <AiFillInfoCircle color="pupple" size="20" />
                  </span>
                </CTooltip>
                </div>
          <CFormInput
            name=""
            defaultValue={affiliation}
            type="text"
            onChange={(e) => handleChange(e, "affiliation")}
          />{" "}
        </CCol>
  
        <CCol md={4}>
          <CFormLabel htmlFor=""> IP Address </CFormLabel>{" "}
          <CFormInput
            type="text"
            name="IPAddress"
            id=""
            defaultValue={IPAddress}
            onChange={(e) => handleChange(e, "IPAddress")}
          />{" "}
        </CCol>
        <CCol md={4}>
          <CFormLabel htmlFor=""> URL </CFormLabel>{" "}
          <CFormInput
            type="text"
            name="URL"
            id=""
            defaultValue={URL}
            onChange={(e) => handleChange(e, "URL")}
          />{" "}
        </CCol>{" "}
        <CCol md={4}>
          <CFormLabel htmlFor=""> Port Number </CFormLabel>{" "}
          <CFormInput
            type="text"
            name="PortNumber"
            id=""
            defaultValue={PortNumber}
            onChange={(e) => handleChange(e, "PortNumber")}
          />{" "}
        </CCol>{" "}
        <CCol md={4}>
          <CFormLabel htmlFor=""> Email </CFormLabel>{" "}
          <CFormInput
            type="text"
            name="Email"
            id=""
            defaultValue={Email}
            onChange={(e) => handleChange(e, "Email")}
          />{" "}
        </CCol>{" "}
        <CCol md={4}>
          <CFormLabel htmlFor=""> Phone Number </CFormLabel>{" "}
          <CFormInput
            type="text"
            name="PhoneNumber"
            id=""
            defaultValue={PhoneNumber}
            onChange={(e) => handleChange(e, "PhoneNumber")}
          />{" "}
        </CCol>
        <CCol xs={12}>
          <button
            className="h-10 text-white bg-gs1-blue w-36"
            onClick={() => dispatch({ type: "PREVIOUS_FORM", payload: 0 })}
          >
            <i className=" fa fa-arrow-left " /> previous page{" "}
          </button>{" "}
          {preview()}{" "}
        </CCol>{" "}
      </CForm>{" "}
    </>
  );
};

export default DigitalGLN;
