import React, { useState } from "react";

import { useUserContext } from "../../context/userContext";
import { loginUser } from "../../services/authService";
import { RenderInput, RenderButton } from "../../common/form";
import { HashLoader } from "react-spinners";
import {
  CCard,
  CCardBody,
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CSpinner,
} from "@coreui/react-pro";
import axios from "axios";
import http from "../../services/http";
import { SPINNER, STOP_SPINNER } from "../../utils/action";
import { toast } from "react-toastify";
import { FaWindows } from "react-icons/fa";
import logo from "../../images/logoGs1.png";
import loginImg from "../../images/login.png";
const apiUrl = "authentication/memberlogin";

const Login = () => {
  const [loginState, setLoginState] = useState(1);
  const {
    dispatch,
    loginLoader,
    companyName,
    companyEmail,
    amountOfBarcode,
    nextRenewal,
    amountTopay,
  } = useUserContext();
  //Convet this to useRef.....
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const getDetails = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setLogin({
      ...login,
      [name]: value,
    });
  };

  const sendResquestforReset = async () => {
    try {
      dispatch({ type: SPINNER });
      const { email } = login;
      await axios({
        url: `${process.env.REACT_APP_BASE_URL}authentication/forgotpassword`,
        method: "POST",
        data: { email },
      })
        .then((res) => {
          if (res.data.message == "Successfully Changed Password") {
            dispatch({ type: STOP_SPINNER });
            toast.success("PLease check your email for the next step");
          } else {
            toast.info();
          }
        })
        .catch((er) => {
          toast.warn("Please try again after some time");
          dispatch({ type: STOP_SPINNER });
        });
    } catch (err) {
      toast.error("Please retry");
    }
  };

  const showForm = () => {};

  const renderLoginState = () => {
    if (loginState == 1) {
      return (
        <div className="my-2 sm:px-2">
          <h1 className="text-center font-black text-[26px]"> Welcome back </h1>{" "}
          <input
            name="email"
            type="email"
            onChange={getDetails}
            placeholder="email@example.com"
            className="lg:px-2 sm:px-1 h-[50px] w-full outline outline-outline outline-1 border-b-2 border-gs1-blue my-10"
          />
          <input
            name="password"
            type="password"
            onChange={getDetails}
            placeholder="**********"
            className="px-2 h-[50px] w-full outline outline-outline outline-1 border-b-2 border-gs1-blue my-1"
          />{" "}
          {renderSubmitButton()}{" "}
          {loginState === 1 ? (
            <p
              className="text-gs1orange my-2 underline font-bold cursor-pointer"
              onClick={() => setLoginState(2)}
            >
              Reset password{" "}
            </p>
          ) : (
            ""
          )}{" "}
        </div>
      );
    } else {
      return (
        <div className="my-2 sm:px-2">
          <h1 className="text-center font-black text-[26px]">
            {" "}
            Reset password{" "}
          </h1>{" "}
          <input
            name="email"
            type="email"
            onChange={getDetails}
            placeholder="email@example.com"
            className="px-2 h-[50px] w-full outline outline-outline outline-1 border-b-2 border-gs1orange my-10"
          />
          <button
            className="bg-gs1orange h-[40px] text-white px-4   w-full mt-4"
            onClick={sendResquestforReset}
          >
            Reset password{" "}
            <span className="mx-5">
              {" "}
              {loginLoader && <HashLoader color="#ffffff" />}{" "}
            </span>{" "}
          </button>{" "}
          {loginState !== 1 ? (
            <p
              className="text-gs1-blue mt-2 underline font-bold cursor-pointer"
              onClick={() => setLoginState(1)}
            >
              Login{" "}
            </p>
          ) : (
            ""
          )}{" "}
        </div>
      );
    }
  };

  const renderSubmitButton = () => {
    if (login.email !== "" && login.password !== "") {
      return (
        <button
          className="bg-gs1-blue h-[40px] text-white px-4 py-2  w-full mt-4"
          onClick={submitDetails}
        >
          Login{" "}
          <span className="mx-5">
            {" "}
            {loginLoader && <HashLoader color="#ffffff" />}{" "}
          </span>{" "}
        </button>
      );
    } else {
      return (
        <p className="text-gs1orange text-[11px] my-1">
          Please enter your email and password{" "}
        </p>
      );
    }
  };

  const submitDetails = async () => {
    const { email, password } = login;
    if (email !== "" && password !== "") {
      dispatch({ type: SPINNER });
      await http
        .post(apiUrl, { email, password })
        .then((res) => {
          if (
            res.status === 200 &&
            res.data.message == "Successfully Retrieved User Record."
          ) {
            dispatch({ type: STOP_SPINNER });
            toast.success("Hello " + " " + email);
            // console.log("data return", res);
            const w = res["data"]["returnedObject"]["companies"];
            const x = res["data"]["returnedObject"]["tokenValidation"];
            const y = res["data"]["returnedObject"]["refreshToken"];
            sessionStorage.setItem("company", JSON.stringify(w));
            sessionStorage.setItem("tokenValidation", JSON.stringify(x));
            // localStorage.setItem("refreshToken", y);
            const z = x + ":" + y;
            sessionStorage.setItem("main", z);
            window.location.href = "/user/home";
          } else {
            dispatch({ type: STOP_SPINNER });
            toast.error(res.data.message);
          }
        })
        .catch((er) => {
          // console.log(er);
        });
    } else {
      // console.log("Provide the details please");
    }
  };

  return (
    <>
    {/* 210px */}
      <div className="h-[525px] bg-white shadow-md my-20 lg:w-[1000px] sm:w-[300px] mx-auto grid lg:grid-cols-2 sm:grid-col-1 ">
        <div className="lg:px-20 lg:py-20 ">
          {" "}
          {/* Image */}{" "}
          <div>
            <img src={logo} className="h-[69px] mx-auto" alt="" />
          </div>{" "}
          {renderLoginState()}{" "}
        </div>{" "}
        <div>
          <img
            src={loginImg}
            className="h-full max-h-screen w-full object-contain"
            alt=""
          />
        </div>{" "}
      </div>{" "}
    </>
  );
};

export default Login;
