import React, { useEffect, useState } from "react";

import {
  CCallout,
  CRow,
  CCol,
  CContainer,
  CFormCheck,
  CButton,
  CSpinner,
} from "@coreui/react";
import sub from "./glnimages/sub.png";
import cloud from "./glnimages/cloud.png";
import ship from "./glnimages/ship.png";
import custom from "./glnimages/custom.png";
import ITF14Form from './itf14form';
import { useITFContext } from "../../context/itfContext";

const ITFTypes = () => {
  
  const { handleITFSelection,
    handleITFItemSelection, selectedtype, handlenext,showform } = useITFContext();

  
      if(showform==1){
        return <ITF14Form />;    
      }

  const rendercard = () => {
    if (selectedtype === "HOMOGENOUS") {
      return (
        <CCallout color="primary">
          An organizational subdivision or department.{" "}
        </CCallout>
      );
    } else if (selectedtype === "HETEROGENOUS") {
      return (
        <CCallout color="secondary">
          A tangible place that is expected to change locations and maybe be
          represented by an address, coordinates or other means.{" "}
        </CCallout>
      );
    }
  };
  const showThisImage = () => {
    if (selectedtype === "HOMOGENOUS") {
      return (
        <img className="gtin-icons" src={sub} style={{ height: 200 }} alt="" />
      );
    } else if (selectedtype === "HETEROGENOUS") {
      return (
        <img
          className="gtin-icons"
          src={ship}
          style={{ height: 200, width: 300 }}
          alt=""
        />
      );
    } 
  };
  return (
    <div>
      <CContainer>
        <h3> Select ITF-14 type </h3>{" "}
       
        {rendercard()}{" "}
        <CRow>
          <CCol sm={8}>
          <CFormCheck
          checked={selectedtype=="HOMOGENOUS"}
          className="mt-3"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              label="Homogenous Case"
              onChange={() => handleITFSelection("HOMOGENOUS")}
            />{" "}
            <CFormCheck
            checked={selectedtype=="HETEROGENOUS"}
            className="mt-3"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              label="Heterogenous/Mixed Case"
              onChange={() => handleITFSelection("HETEROGENOUS")}
            />{" "}       
           
           
          
          </CCol>{" "}
          <CCol sm={4}> {showThisImage()} </CCol>{" "}
        </CRow>{" "}
        {selectedtype != "" ? (
          <button
            className="h-10 text-white bg-gs1-blue w-36"
            onClick={handlenext}
          >
            Next <i className="fa fa-arrow-right " />
          </button>
        ) : (
          <p style={{ color: "red" }}> "please select an ITF-14 type" </p>
        )}{" "}
      </CContainer>{" "}
    </div>
  );
};

export default ITFTypes;
