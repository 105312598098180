import React, { useState, Component } from "react";
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CCard,
  CCardBody,
  CContainer,
} from "@coreui/react";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";

import Step4 from "./step4";
import ShowDetails from "./reviewDet";

import { useProductListingContext } from "../../context/productListingContext";
//product listing form
const CreateGtn = () => {
  const { level } = useProductListingContext();
  const renderForm = () => {
    switch (level) {
      case 1:
        return <Step1 />;

      case 2:
        return <Step2 />;

      case 3:
        return <Step3 />;

      case 4:
        return <Step4 />;

      case 5:
        return <ShowDetails />;
        break;
    }
  };
  return (
    <div className="mx-3 py-3">
      {" "}
      <br />
      <CCard>
        <CCardBody className="create_gtn-card border-t-4 border-gs1-blue">
          {" "}
          {renderForm()}{" "}
        </CCardBody>{" "}
      </CCard>{" "}
    </div>
  );
};

export default CreateGtn;
