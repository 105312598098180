import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { CButton } from "@coreui/react";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";

const GenerateExcel = ({ eData, name }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(eData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, name + fileExtension);
  };

  return (
    <>
      <CButton color="light" onClick={(e) => exportToExcel(name)}>
        Download as {<BsFillFileEarmarkExcelFill size={20} color="green" />}{" "}
      </CButton>
    </>
  );
};

export default GenerateExcel;
