import React from "react";
import {
  CContainer,
  CRow,
  CCol,
  CForm,
  CButton,
  CFormInput,
  CFormLabel,
  CFormCheck,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CTooltip,
} from "@coreui/react";
// import { CFormSwitch } from '@coreui/react'
import { dayMonth, measurement } from "../fakedata/packaging";
import { useState } from "react";
import { useProductListingContext } from "../context/productListingContext";
import { RenderInput, RenderSelect, RenderTextArea } from "../common/form";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AiFillInfoCircle } from "react-icons/ai";
import "react-tooltip/dist/react-tooltip.css";

const HealthCareAttribute = ({ }) => {
  const [measure, setMeasure] = useState(measurement);
  const [dayOrWeek, setDayOrWeek] = useState(dayMonth);

  //   console.log("Toggle state", toggleHealthCare);
  const {
    functionalName,
    genericName,
    tradeItemDescription,
    strength,
    activeIngredients,
    ActiveIngredient,
    productForm,
    manufacturer,
    manufacturerAddress,
    atcCode,
    routeOfAdministration,
    shelfLife,
    units,
    packSize,
    quantity,
    directionOfUse,
    primaryPackaginMaterial,

    handleChange,
  } = useProductListingContext();

  return (
    <>
      <CContainer>
        <h3>Healthcare product information</h3>
        <p>Explanation of Healthcare attributes</p>

        <div className="row g-3">
          <CCol md={3}>
            <CFormLabel htmlFor="">
              Functional name{" "}
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="This describes the use of the product or service by the consumer."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              className={functionalName === "" ? "empty" : ""}
              type="text"
              name="functionalName"
              id=""
              defaultValue={functionalName}
              onChange={(e) => handleChange(e, "functionalName")}
            />
          </CCol>
          <CCol md={3}>
            <CFormLabel htmlFor="">
              Generic name
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="The official nonproprietary name of a drug, under which it is licensed and identified by manufacturer. Generic name of a pharmaceutical from the World Health Organization International Non-proprietary Names system is recommended."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              type="text"
              name="genericName"
              id=""
              className={genericName === "" ? "empty" : ""}
              defaultValue={genericName}
              onChange={(e) => handleChange(e, "genericName")}
            />
          </CCol>

          <CCol md={3}>
            <CFormLabel htmlFor="">
              Strength
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="The strength of a drug product tells how much of the active ingredient is present in each dosage."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              type="text"
              name="strength"
              id=""
              className={strength === "" ? "empty" : ""}
              defaultValue={strength}
              onChange={(e) => handleChange(e, "strength")}
            />
          </CCol>
          {/* Batch 2 */}
          <CCol md={3}>
            <CFormLabel htmlFor="">
              {" "}
              Product form
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="The way a medicine is presented such as a tablet, a capsule, or an injectable, cream, solution for injection, etc."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              type="text"
              name=" productForm"
              id=""
              className={productForm === "" ? "empty" : ""}
              defaultValue={productForm}
              onChange={(e) => handleChange(e, "productForm")}
            />
          </CCol>
          <CCol md={3}>
            <CFormLabel htmlFor="">
              ATC code
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="Anatomical Therapeutic Chemical/Defined Daily Dose pharmaceutical classification structure number."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              type="text"
              name="atcCode"
              id=""
              className={atcCode === "" ? "empty" : ""}
              defaultValue={atcCode}
              onChange={(e) => handleChange(e, "atcCode")}
            />
          </CCol>
          <CCol md={3}>
            <CFormLabel htmlFor="">
              Shelf life (X Months)
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="The period of time, from the date of manufacture, that a product is expected to remain within its approved product specification while handled and stored under defined conditions."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              type="text"
              name="shelfLife"
              id=""
              className={shelfLife === "" ? "empty" : ""}
              defaultValue={shelfLife}
              onChange={(e) => handleChange(e, "shelfLife")}
            />
          </CCol>
          <CCol md={3}>
            <CFormLabel htmlFor="">
              Pack Size{" "}
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="The amount of product in a pack or container."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              type="text"
              name="packSize"
              id=""
              className={packSize === "" ? "empty" : ""}
              defaultValue={packSize}
              onChange={(e) => handleChange(e, "packSize")}
            />
          </CCol>
          {/* <CCol md={3}>
            {RenderSelect("packunit", "Pack unit", units)}
          </CCol> */}

          {/* <CCol md={3}>
            <CFormLabel htmlFor="">Primary Packaging Material</CFormLabel>
            <CFormInput
              type="text"
              name="primaryPackaginMaterial"
              id=""
              className={primaryPackaginMaterial === "" ? "empty" : ""}
              defaultValue={primaryPackaginMaterial}
              onChange={(e) => handleChange(e, "primaryPackaginMaterial")}
            />
          </CCol> */}

          <CCol md={3}>
            <CFormLabel htmlFor="">
              Quantity{" "}
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="The total dosage units contained in one unit of the drug product. The quantity or weight of the drug given as a proportion of the PACK size."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              type="text"
              name="Quantity"
              id=""
              className={quantity === "" ? "empty" : ""}
              defaultValue={quantity}
              onChange={(e) => handleChange(e, "quantity")}
            />
          </CCol>

          <CCol md={3}>
            <CFormLabel htmlFor="">
              Active Ingredients
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="An active ingredient is any component that provides a pharmacological activity or other direct effects in the diagnosis, cure, mitigation, treatment, or prevention of disease, or to affect the structure or any function of the body of humans."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              name="activeIngredients"
              className={activeIngredients === "" ? "empty" : ""}
              defaultValue={activeIngredients}
              onChange={(e) => handleChange(e, "activeIngredients")}
            ></CFormInput>
          </CCol>
          <CCol md={3}>
            <CFormLabel htmlFor="">
              Direction of use{" "}
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="The directions under which a layman can use a drug safely for the purposes for which it is intended. They tell you exactly how and when to take a medication."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              name="directionOfUse"
              className={directionOfUse === "" ? "empty" : ""}
              defaultValue={directionOfUse}
              onChange={(e) => handleChange(e, "directionOfUse")}
            ></CFormInput>
          </CCol>
          <CCol md={3}>
            <CFormLabel htmlFor="">
              Route of administration
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="The method(s) of administering the product. In pharmacology and toxicology, a route of administration is the path by which a drug, fluid, or other substance is brought into contact with the body."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              name=" routeOfAdministration"
              className={routeOfAdministration === "" ? "empty" : ""}
              defaultValue={routeOfAdministration}
              onChange={(e) => handleChange(e, "routeOfAdministration")}
            ></CFormInput>
          </CCol>
          <CCol md={3}>
            <CFormLabel htmlFor="">
              Trade Item description
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="Same as above but for aggregates (shippers/pallets identified with GTINs) e.g. 20 packs of atorvastatin 10mg tabs for shippers."
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              name="tradeItemDescription"
              className={tradeItemDescription === "" ? "empty" : ""}
              defaultValue={tradeItemDescription}
              onChange={(e) => handleChange(e, "tradeItemDescription")}
            ></CFormInput>
          </CCol>
          <CCol md={6}>
            <CFormLabel htmlFor="">
              Manufacturer
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="The name of the manufacturer of the product"
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              name="manufacturer"
              className={manufacturer === "" ? "empty" : ""}
              defaultValue={manufacturer}
              onChange={(e) => handleChange(e, "manufacturer")}
            ></CFormInput>
          </CCol>
          <CCol md={6}>
            <CFormLabel htmlFor="">
              Manufacturer Address
              <CTooltip
                trigger={"hover"}
                placement="top"
                content="The address of the manufacturer of the product"
              >
                <span>
                  <AiFillInfoCircle color="blue" size="20" />
                </span>
              </CTooltip>
            </CFormLabel>
            <CFormInput
              name="manufacturerAddress"
              className={manufacturerAddress === "" ? "empty" : ""}
              defaultValue={manufacturerAddress}
              onChange={(e) => handleChange(e, "manufacturerAddress")}
            ></CFormInput>
          </CCol>

         
        </div>
      </CContainer>
    </>
  );
};

export default HealthCareAttribute;
