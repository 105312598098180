//NAVIGATION ACTION $ GTIN MANAGEMENT
export const NEXT_STAGE = 'NEXT_STAGE'
export const PREVIOUS_STAGE = 'PREVIOUS_STAGE'
export const HANDLE_PACKAGING = 'HANDLE_PACKAGING'
export const UPDATE_INPUT = 'UPDATE_INPUT'
export const UPDATE_MARKET = 'UPDATE_MARKET'
export const PACKAGING_TYPES = 'PACKAGING_TYPES'
export const LISTED_BRANDS = 'LISTED_BRANDS'
export const PACKAGING_LEVEL = "PACKAGING_LEVEL"
export const ERROR_FOUND = "ERROR_FOUND"
export const SPINNER = "SPINNER"
export const STOP_SPINNER = "STOP_SPINNER"

