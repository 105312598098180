import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import "@coreui/coreui-pro/dist/css/coreui.min.css";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./context/productListingContext";
import { UserProvider } from "./context/userContext";
import { GlnProvider } from "./context/glnContext";
import { ITFProvider } from "./context/itfContext";
ReactDOM.render(
  <BrowserRouter>
    <GlnProvider>
      <ITFProvider>
      <UserProvider>
        <AppProvider>
          <App />
        </AppProvider>{" "}
      </UserProvider>{" "}
      </ITFProvider>
    </GlnProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
