import React from "react";
import {
  CCard,
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CForm,
  CFormInput,
  CFormSwitch,
  CFormLabel,
  CFormSelect,
  CButton,
  CSpinner,
} from "@coreui/react";
import { FcMoneyTransfer } from "react-icons/fc";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { useUserContext } from "../context/userContext";
import { divide } from "lodash";
import { additionalRequest, imageRequest } from "../services/generalService";
const BarcodeImages = () => {
  const [quantity, setQuantity] = useState(0);
  const [cost, setCost] = useState();
  const [loading, setLoading] = useState(false);

  const { totalGtinsRequested, registrationid } = useUserContext();
  console.log("Total requested gtings", totalGtinsRequested);
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  const formatCostF = () => {
    if (quantity == 0) {
      setCost(0);
      // return cost
    } else if (quantity > 0 && quantity <= 50) {
      const pay = quantity * 2000;
      setCost(pay);
      // return formatCost(pay)
    } else if (quantity > 50 && quantity <= 100) {
      const pay = quantity * 1500;
      setCost(pay);
      // return formatCost(pay)
    } else if (quantity > 100 && quantity <= 400) {
      const pay = quantity * 1500;
      setCost(pay);
      // return formatCost(pay)
    } else if (quantity > 499) {
      toast.warning(
        "For images up to 500 please contact the admin for price thank you"
      );
      const pay = quantity * 1500;
      setCost(pay);
      // return formatCost(pay)
    }
  };
  const requestForAdditionalImage = async () => {
    if (quantity > 0) {
      try {
        setLoading(true);
        const sendDet = await imageRequest({
          registrationid,
          imagecount: quantity,
        });
        if (sendDet.status == 201 || (200 && sendDet.data.isSuccess == true)) {
          toast.success("Done");
          setLoading(false);
          // console.log("something happened", sendDet);
        } else {
          toast.info(sendDet.message);
          setLoading(false);
        }
      } catch (er) {
        toast.info("You have a pending request");
        setLoading(false);
      }
    } else {
      toast.warn("Qty cannot be zero");
    }
  };

  const showBtn = () => {
    if (quantity > totalGtinsRequested) {
      return (
        <>
          <p style={{ color: "red" }}>
            Your total GTIN is less than {quantity}
          </p>
        </>
      );
    } else {
      return (
        <button
          className="bg-gs1-blue text-white h-10 w-36 my-3"
          component="a"
          onClick={() => requestForAdditionalImage()}
          href="#"
          role="button"
        >
          Make request
          {loading && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
        </button>
      );
    }
  };

  useEffect(() => {
    formatCostF();
  }, [quantity]);

  return (
    <>
      <br></br>
      <div className="mx-3 my-3">
        <CCard>
          <CCardBody className="gtin-card">
            <h3 className="">Purchase barcode Image</h3>
            <p className="text-[14px] font-bold">
              {" "}
              Account Name:GS1 System Nigeria, Account Number: 2015342894, Bank
              Name:FBN{" "}
            </p>
            <hr />
            <CRow>
              <CCol>
                <CForm onSubmit={(e) => e.preventDefault()}>
                  <CFormInput
                    type="number"
                    name="imageCount"
                    min="0"
                    max="2001"
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  <br></br>
                  {showBtn()}
                  {/* <CButton component="a" color="primary" href="#" role="button">
                    Make request
                  </CButton> */}
                </CForm>
              </CCol>
              <CCol>
                <CCard style={{ width: "18rem" }}>
                  {<FcMoneyTransfer size={40} />}
                  <CCardBody>
                    <h1>{formatter.format(cost)}</h1>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol></CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
    </>
  );
};

export default BarcodeImages;
