import {
  CContainer,
  CRow,
  CCol,
  CForm,
  CButton,
  CFormInput,
  CFormLabel,
  CFormCheck,
  CFormSelect,
  CFormSwitch,
  CTooltip,
  CSpinner 
} from "@coreui/react";

import Select from "react-select";
// import { CDatePicker } from "@coreui/react-pro@next";
import React, { useState, useEffect } from "react";
import { useProductListingContext } from "../../context/productListingContext";
import // packaging,
// productCategory,
// stateOptions,
"../../fakedata/packaging";
import { Link } from "react-router-dom";
// import { options } from "joi-browser";
// import { set } from "lodash";
// import { forEach } from "lodash";

const Step2 = () => {
  const {
    brands,
    nextPage,
    previousStage,
    level,
    loadPackageType,
    productdescription,
    consumerfirstavailabilitydate,
    dispatch,
    handlePackageChange,
    previousPage,
    TargetMarketList,
    packagingtype_id,
    brandinformation_id,
    handleChange,
    handleTargetMarket,
    brickcategory_id,
    bricks,
    handleSelect,
    targetedMarket,
  } = useProductListingContext();

  // console.log("shity", brickcategory_id);

  const brickmap = bricks.map((i) => {
    // console.log("i-----", i);
    const maped = {};
    maped.value = i.id;
    maped.label = i.brick;
    return maped;
  });

  // console.log("brickmap something", brickcategory_id);

  const targeted_market = targetedMarket.map((item) => {
    const container = {};

    container.value = item.id;
    container.label = item.name;

    return container;
  });
  useEffect(() => {
    const getCountryValue = () => {
      const b = TargetMarketList.map((target) => {
        // console.log("oaok", target);
        const filteredItem = targeted_market.find((x) => x.value === target);
        return filteredItem ? filteredItem.label : null;
      });

      // console.log("b", b);
      dispatch({ type: "SHOW_MARKET", payload: b });
    };

    getCountryValue();
  }, [TargetMarketList]);

  // useEffect(() => {
  //   const getCountryValue = () => {
  //     var b = [];
  //     for (let i = 0; i < TargetMarketList.length; i++) {
  //       targeted_market.filter((x) => x.value == TargetMarketList[i]);
  //       b.push(targeted_market[0].label);
  //       console.log(targeted_market);
  //     }
  //     console.log("b", b);
  //     dispatch({ type: "SHOW_MARKET", payload: b });
  //   };
  //   getCountryValue();
  // }, [TargetMarketList]);

  const option = loadPackageType.map((item) => {
    const container = {};

    container.value = item.id;
    container.label = item.name;

    return container;
  });

  const brandOption = brands.map((item) => {
    const brandMap = {};

    brandMap.value = item.id;
    brandMap.label = item.brandname;

    return brandMap;
  });

  const firstDefault = [
    {
      value: "--select--",
      label: "--select a valid option--",
    },
  ];
  const ModifiedOption = firstDefault.concat(brandOption);
  // console.log("modified details", ModifiedOption);
  const ModifiedPackage = firstDefault.concat(option);
  // console.log("packaging ID", ModifiedPackage);

  const renderNextButton = () => {
    if (
      productdescription == "" ||
      packagingtype_id == "--select--" ||
      brandinformation_id == "--select--" ||
      consumerfirstavailabilitydate == "" ||
      TargetMarketList == "" ||
      brickcategory_id == ""
    ) {
      return (
        <button
          className="bg-gs1-color-orange text-white h-10 w-52 my-3"
          disabled
        >
          Please fill in all the fields{" "}
        </button>
      );
    } else {
      return (
        <button
          className="bg-gs1-color-orange text-white h-10 w-36 my-3"
          onClick={nextPage}
        >
          Next <i className="fa fa-arrow-right " />
        </button>
      );
    }
  };

  return (
    <>
      <h3> Product details </h3>{" "}
      <p style={{ color: "red" }}>
        {" "}
        * please note that all field are mandetory *{" "}
      </p>{" "}
      <hr />
      <CForm
        className="row g-3"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <CCol md={4}>
          <CFormLabel> Product description </CFormLabel>{" "}
          <CFormInput
            name="productdescription"
            defaultValue={productdescription}
            type="text"
            onChange={(e) => handleChange(e, "productdescription")}
          />{" "}
        </CCol>
        <CCol md={4}>
          <CFormLabel htmlFor="inputPassword4">
            Consumer first availability date{" "}
          </CFormLabel>{" "}
          <CFormInput
            type="date"
            name="dateOfActivation"
            id=""
            defaultValue={consumerfirstavailabilitydate}
            onChange={(e) => handleChange(e, "consumerfirstavailabilitydate")}
          />{" "}
        </CCol>
        <CCol md={4}>
          <CFormLabel htmlFor="">
            Select brand{" "}
            <span>
              <Link to="/manage_brands"> Add New Brand </Link>{" "}
            </span>{" "}
          </CFormLabel>{" "}
          <CFormSelect
            name="brandinformation_id"
            defaultValue={brandinformation_id}
            options={ModifiedOption}
            onChange={(e) => handleChange(e, "brandinformation_id")}
          />{" "}
        </CCol>{" "}
        <CCol md={4}>
          <CFormLabel htmlFor=""> Packaging Type </CFormLabel>{" "}
          <CFormSelect
          
            name="packagingtype_id"
            defaultValue={packagingtype_id}
            options={ModifiedPackage}
            onChange={(e) => handleChange(e, "packagingtype_id")}
          />{" "}
        </CCol>
        <CCol md={4}>
          <CFormLabel htmlFor=""> Target Market </CFormLabel>{" "}
          <Select
            className="dropdown"
            placeholder="Select Option"
            value={targeted_market.filter((obj) =>
              TargetMarketList.includes(obj.value)
            )} // set selected values
            options={targeted_market} // set list of the data
            onChange={(e) => handleSelect(e, "TargetMarketList")} // assign onChange function
            isMulti
            isClearable
            name="TargetMarketList"
          />
        </CCol>{" "}
        <CCol md={4}>
          <CFormLabel htmlFor=""> Product Category  </CFormLabel>
          <Select
            className="dropdown"
            placeholder="Select Option"
            value={brickmap.filter((i) => i.value == brickcategory_id)[0]} // set selected values
            options={brickmap} // set list of the data
            onChange={(e) => handleSelect(e, "brickcategory_id")} // assign onChange function
            // isMulti
            name="brickcategory_id"
            isClearable
          />
         
        </CCol>
        <CCol xs={12}>
          <button
            className="bg-gs1-blue text-white h-10 w-36 my-3"
            // onClick={nextPage}
            // style={{ marginLeft: 10 }}
            // shape="rounded-pill"
            onClick={previousPage}
          >
            <i className=" fa fa-arrow-left " /> previous page{" "}
          </button>{" "}
          {renderNextButton()}{" "}
        </CCol>{" "}
      </CForm>{" "}
    </>
  );
};

export default Step2;
