import React from "react";
import {
  CCard,
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CForm,
  CFormInput,
  CFormSwitch,
  CFormLabel,
  CFormSelect,
  CButton,
  CSpinner,
} from "@coreui/react";
import { FcDownload } from "react-icons/fc";
import template from "../file/OnesourceTemplate.xlsx";

import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { useUserContext } from "../context/userContext";
import { divide, set } from "lodash";
import { additionalRequest, imageRequest } from "../services/generalService";
import { cilCloudDownload, cilCloudUpload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import axios from "axios";

const ProductUpload = () => {
  const mimeType = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
  const [submitted, setSubmitted] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [fileMeta, setfileMeta] = useState({
    name: "",
    size: 0,
    type: "",
    file: null
  });
  const [errList, seterrList] = useState([]);


  const submitProduct = async () => {
    const formData = new FormData();
    formData.append('file', fileMeta.file);
    setSubmitted(true);
    seterrList([]);

    try {
      // dispatch({ type: "SAVE_PRODUCTS" });
      await axios({
        url: `${process.env.REACT_APP_BASE_URL}productupload/createfromexcel`,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          setSubmitted(false);
          // console.log(res);
          if (res.data.isSuccess == true) {
            toast.success(res.data.message);
            // dispatch({ type: "SAVED_OR_WHATEVER" });
            // dispatch({ type: "CLEAR" });
            window.location.href = "/user/viewproducts";
          } else {
            toast.info(res.data.message);
            // dispatch({ type: "SAVED_OR_WHATEVER" });
          }
        })
        .catch((er) => {
          // console.log(er)
          let errObj = er.response.data.returnedObject;
          seterrList(...errList, errObj);
          setSubmitted(false);
          toast.warn("please retry");
          // dispatch({ type: "SAVED_OR_WHATEVER" });
        });
    } catch (err) {
      setSubmitted(false);
      // dispatch({ type: "SAVED_OR_WHATEVER" });
      // console.log("error", err);
    }


  }


  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  const onFileChange = (e) => {
    setUploadMessage("");
    setfileMeta({ ...fileMeta, name: "" });
    if (mimeType.indexOf(e.target.files[0].type) == -1) {
      setUploadMessage("wrong file format");
      return;
    }
    setfileMeta({
      ...fileMeta,
      name: e.target.files[0].name,
      type: e.target.files[0].type,
      size: formatBytes(e.target.files[0].size, 2),
      file: e.target.files[0]
    });

    // fileMeta["name"] = e.target.files[0].name;
    // fileMeta["type"] = e.target.files[0].type;
    // fileMeta["size"] = formatBytes(e.target.files[0].size, 2);
    // setState(fileMeta);


  }
  const errTable = () => {
    if (errList.length > 0) {
      return (
        <div className="card mt-5">
          <div className="card-header text-warning fw-bold d-flex justify-content-between"><span>Error Information</span></div>
          <div className="card-body">
            <div
              class="table-responsive"
            >
              <table
                class="table table-striped table-hover table-bordered  align-middle"
              >
                <thead class="table-light">
                 
                  <tr>
                    <th>Row Number</th>
                    <th>GTIN</th>
                    <th>Error Messsage</th>
                  </tr>
                </thead>
                <tbody class="table-group-divider">
                 


                  {errList.map((v, i) => {
                  return  <tr key={i}
                      class="table-default"
                    >
                      <td className="fw-bold" scope="row">{v.rowNum}</td>
                      <td>{v.gtin}</td>
                      <td>{v.message}</td>
                    </tr>

                  })}


                </tbody>
                <tfoot>

                </tfoot>
              </table>
            </div>

          </div>
          <div className="card-footer text-muted">Footer</div>
        </div>

      );
    }
  }
  const meta = () => {
    if (fileMeta.name != "") {
      return (
        <div className="mt-5">
          <div>{uploadMessage != "" && <span>{uploadMessage}</span>}</div>
          <h4 className="fw-bold">File Details:</h4>
          <p>
            File Name:{" "}
            {fileMeta.name}
          </p>

          <p>
            File Type:{" "}
            {fileMeta.type}
          </p>

          <p>
            File Size:{" "}
            {fileMeta.size}
          </p>

          <div>
            <CButton
              onClick={submitProduct}
              className="mx-3" component="a" color="primary">

              {submitted && (
                <CSpinner component="span" size="sm" aria-hidden="true" />
              )}
              Submit

            </CButton>
          </div>
        </div>
      );

    }
  }

  return (
    <>
      <br></br>
      <div className="mx-3 my-3">
        <CCard>
          <CCardBody className="gtin-card">
            <h3 className="">Product Information Excel Upload</h3>
            <p className="text-[14px] font-bold">
              {" "}
              NOTE: (Only a Standard Microsoft Excel Sheet .xslx or .xsl should be uploaded). Kindly note that all columns must correspond with this <a href="">sample</a>, else your data will not be uploaded to server. {" "}
            </p>

            <CButton color="warning" className="fw-bold">
              <CIcon icon={cilCloudDownload} title="Download file" />  <a href={template} target="_blank" download="ProductUpload-template">Download Template</a>
            </CButton>

            <hr />
            <CRow>
              <CCol md={12} className="d-flex">
                <CForm className="d-flex w-75" onSubmit={(e) => e.preventDefault()}>
                  <CFormInput
                    type="file"
                    name="imageCount"

                    onChange={onFileChange}
                  />

                  {/* {showBtn()} */}


                </CForm>
                <CButton className="mx-3" component="a" color="primary">
                  <span>Upload</span><CIcon icon={cilCloudUpload} />

                </CButton>
              </CCol>

              <CCol></CCol>
            </CRow>
            <CRow>
              <CCol md={12}>
                <div className="">
                  {/* <div>
                    <h2>File Details:</h2>
                    <p>
                      File Name:{" "}
                      {state.name}
                    </p>

                    <p>
                      File Type:{" "}
                      {state.type}
                    </p>

                    <p>
                      File Size:{" "}
                      {state.size}
                    </p>
                  </div> */}

                  {meta()}
                  {errTable()}
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
    </>
  );
};

export default ProductUpload;
