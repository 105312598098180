import React from "react";
import { useState, useEffect } from "react";
import { CSmartTable } from "@coreui/react-pro";
import { useProductListingContext } from "../context/productListingContext";
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CCard,
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CForm,
  CFormInput,
  CButton,
  CFormLabel,
  CFormText,
  CFormSwitch,
  CSpinner,
} from "@coreui/react";
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react-pro";
import { saveBrand } from "../services/generalService";
import { toast } from "react-toastify";
import { useUserContext } from "../context/userContext";
import brand_image from "../components/barcodecomponent/images/brand.png";
import { parseISO, formatDistanceToNow } from "date-fns";
import { BiEdit } from "react-icons/bi";
import axios from "axios";
import { set } from "lodash";
// import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
// import { CModal } from "@coreui/react-pro";

const Brands = () => {
  const { brands, loading, dispatch } = useProductListingContext();
  const { registrationid } = useUserContext();
  const [brandToEdit, setBrandToEdit] = useState("");
  const [visible, setVisible] = useState(false);
  const [loadingHere, setLoadingHere] = useState(false);
  // const regID = parseInt(registrationid);
  const [brandDetails, setBrandDetails] = useState({
    brandname: "",
    registrationid: registrationid,
    isActive: false,
  });

  //Reload after brand upda

  // Extract this to a component latter
  const formatTime = (timestamp) => {
    const date = parseISO(timestamp);
    const period = formatDistanceToNow(date);
    return <td>{period} ago</td>;
  };

  const [activeKey, setActiveKey] = useState(1);

  const saveBrands = async () => {
    dispatch({ type: "BRAND_SAVE" });
    try {
      const a = await saveBrand(brandDetails);
      if (a.status === 201) {
        toast.success("Sucessful");

        dispatch({ type: "BRAND_SAVED" });
        window.location.href = "/user/creategtin";
      } else if (a.status === 400) {
        toast.error("Please check your internet ");
        dispatch({ type: "ERROR_SAVING_BRAND" });
      } else {
        toast.error("Please check your internet ");
        dispatch({ type: "ERROR_SAVING_BRAND" });
      }
    } catch (ex) {
      toast.warning("someting is wrong");
      dispatch({ type: "ERROR_SAVING_BRAND" });
    }
  };
  //update brand name
  const handleBrandNameUpdate = async () => {
    setLoadingHere(true);
    const { brandname } = brandDetails;
    let id = brandToEdit.id;
    try {
      await axios({
        url: `${process.env.REACT_APP_BASE_URL}brandinformation/updatebrandinformation`,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: { brandname, id },
      })
        .then((res) => {
          if (res.status == 200) {
            toast.success("Brand name updated successfully");
            setLoadingHere(false);
            setVisible(false);
            setBrandDetails({ brandname: "" });
            dispatch({ type: "BRAND_UPDATED" });
          } else {
            toast.success("Brand name not updated");
            setLoadingHere(false);
            setBrandDetails({ brandname: "" });
          }
        })
        .catch((er) => {
          toast.warn("Please try again after some time");
          // dispatch({ type: "PREVIOUS_FORM", payload: 0 });
          toast.error("Please retry");
        });
    } catch (err) {
      toast.error("Please retry");
      // dispatch({ type: "PREVIOUS_FORM", payload: 0 });
      // console.log("error", err);
    }
  };

  const showBrandBtn = () => {
    if (brandDetails.brandname !== "") {
      return (
        <CButton
          type="submit"
          color="primary"
          className="btn-10px"
          onClick={saveBrands}
        >
          {loading && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
          Submit
        </CButton>
      );
    } else {
      return <p>Please enter your brand name</p>;
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setBrandDetails({
      ...brandDetails,
      [name]: value,
    });
  };

  // const editbrand = () => {};
  const editBrand = (brand) => {
    setBrandToEdit(brand);
    setVisible(true);
    // console.log(brand);

    // setActiveKey(2);
  };

  const handleToggle = (e) => {
    setBrandDetails({
      ...brandDetails,
      isActive: !brandDetails.isActive,
    });
  };

  const columns = [
    {
      key: "brandname",
      label: "Brand Name",
    },
    {
      key: "createddate",
      label: "Date of creation",
      filter: false,
      sorter: false,
    },
    {
      key: "isActive",
      filter: false,
      sorter: false,
      label: "Brand status",
    },
    {
      key: "edit_brand",
      filter: false,
      sorter: false,
      label: "",
    },
  ];
  const closeModal = () => {
    setVisible(false);
    dispatch({ type: "CLOSE_MODAL" });
  };
  return (
    <div>
      <br />
      <CModal visible={visible} onClose={() => closeModal()}>
        <CModalHeader onClose={() => dispatch({ type: "CLOSE_MODAL" })}>
          <CModalTitle>Edit Brand Information</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <input
            name="brandname"
            onChange={handleChange}
            type="text"
            value={
              brandDetails.brandname == ""
                ? brandToEdit.brandname
                : brandDetails.brandname
            }
            className="w-full px-2 h-10 outline-1 outline border-b-2 border-b-gs1-blue"
          />
          {/* <img src={showBarcode} alt="" style={{ height: "120px" }} /> */}
          {/* <BarcodeGenerator /> */}
        </CModalBody>
        <CModalFooter>
          <CButton onClick={() => setVisible(false)} color="secondary">
            Close
          </CButton>
          {/* <a href=`${downloadBarcode}` download></a> */}
          <CButton color="primary" onClick={() => handleBrandNameUpdate()}>
            Save
          </CButton>
        </CModalFooter>
      </CModal>

      <div className="mx-3 my-3 border-t-2 border-gs1-blue">
        {" "}
        <CCard className="border-t-2 border-gs1-blue">
          <CCardBody className="brand-card">
            <CNav variant="pills" role="tablist">
              <CNavItem>
                <CNavLink
                  href="javascript:void(0);"
                  active={activeKey === 1}
                  onClick={() => setActiveKey(1)}
                >
                  My Brands
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="javascript:void(0);"
                  active={activeKey === 2}
                  onClick={() => setActiveKey(2)}
                >
                  Create new brand
                </CNavLink>
              </CNavItem>
            </CNav>
            <CTabContent>
              <CTabPane
                role="tabpanel"
                aria-labelledby="home-tab"
                visible={activeKey === 1}
              >
                <CSmartTable
                  activePage={3}
                  cleaner
                  clickableRows
                  columns={columns}
                  columnFilter
                  columnSorter
                  items={brands}
                  itemsPerPageSelect
                  itemsPerPage={5}
                  pagination
                  scopedColumns={{
                    createddate: (p) => formatTime(p.createddate),
                    isActive: (p) => {
                      if (p.isActive == true) {
                        return <td>Active</td>;
                      } else {
                        return <td>Inactive</td>;
                      }
                    },
                    edit_brand: (p) => (
                      <td>
                        <BiEdit
                          onClick={() => editBrand(p)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    ),
                  }}
                  // selectable
                  sorterValue={{ column: "name", state: "asc" }}
                  tableFilter
                  tableHeadProps={{
                    color: "",
                  }}
                  tableProps={{
                    striped: false,
                    // bordered: true,
                    hover: true,
                  }}
                />
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="profile-tab"
                visible={activeKey === 2}
              >
                <div>
                  <CRow>
                    <CCol sm={6}>
                      <CForm onSubmit={(e) => e.preventDefault()}>
                        <div className="mb-3">
                          <CFormLabel htmlFor="exampleInputEmail1">
                            Brand name
                          </CFormLabel>
                          <CFormInput
                            type="text"
                            aria-describedby="emailHelp"
                            name="brandname"
                            onChange={handleChange}
                          />
                        </div>
                        <CFormSwitch
                          label="toggle switch to make brand active"
                          id="formSwitchCheckChecked"
                          onChange={handleToggle}
                          name="isActive"
                        />

                        {/* Add btn here */}
                        {showBrandBtn()}
                      </CForm>
                    </CCol>
                    <CCol sm={6}>
                      <img
                        className="h-20"
                        src={brand_image}
                        height={50}
                        alt="brand image"
                      />
                      <p>
                        A brand is a name given by the manufacturer/producer of
                        a product or range of products. You can go further by
                        making it a patient right to your organization with
                        trademark.
                      </p>
                    </CCol>
                  </CRow>
                </div>
              </CTabPane>
            </CTabContent>
          </CCardBody>
        </CCard>
      </div>
    </div>
  );
};

export default Brands;
