import { CContainer } from "@coreui/react";
import { React, useState, useEffect } from "react";
import { CRow, CCol, CForm, CButton, CSpinner } from "@coreui/react";
// import { targetMarket } from "./../../fakedata/market";
// import { useProductListingContext } from "../../context/productListingContext";
import { useITFContext } from "../../context/itfContext";
// import { useUserContext } from "../../context/userContext";
// import { useEffect } from "react";
// import { saveProduct } from "../../services/generalService";
import { toast } from "react-toastify";
import { fromPairs, isArray } from "lodash";
import axios from "axios";
import { log } from "joi-browser";

const ITF14Review = () => {

  const {
    selectedtype,
    products,
    description,
    itfinfo,
    dispatch,
    handleChange,
    handleITFSelection,
    handleITFItemSelection,
    itemcount,
    level
  } = useITFContext();

  const getStoredItems = () => {
    const a = sessionStorage.getItem("company");
    return a ? JSON.parse(a) : {};
  };


  const { registrationid } = getStoredItems();
  var _items=isArray(itfinfo)? itfinfo.map(m=>m.label):[itfinfo.label]

  const data_to_send = {
    registrationId: registrationid,
    description: description,
    itemsInfo: _items,
    codeType: selectedtype,
    level: level.value,
    contentCount: itemcount,
    itF14Code: ""

  };

  // console.log("Company name", companyname);

  const saveITF14Details = async () => {
    try {
      console.log(data_to_send);
      await axios({
        url: `${process.env.REACT_APP_BASE_URL}itf14/createorupdate`,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: data_to_send,
      })
        .then((res) => {
          if (res.data.isSuccess == true) {
            console.log(res.data.returnedObject)
            dispatch({ type: "POPULATE_ITFS", payload: res.data.returnedObject});
            toast.success("ITF-14 SAVED!");
            setTimeout(() => {
              window.location.href = "/user/viewitf14s";

            }, 3000);

          } else {
            toast.warn(res.data.message);
            setTimeout(() => {
              window.location.href = "/user/viewitf14s";

            }, 5000);
            // dispatch({ type: "PREVIOUS_FORM", payload: 0 });
          }

        })
        .catch((er) => {
          toast.warn("Something went wrong, contact GS1 Nigeria");
          dispatch({ type: "PREVIOUS_FORM", payload: 0 });
          // toast.error("Please retry");
        });
    } catch (err) {
      toast.error("Please retry");
      dispatch({ type: "PREVIOUS_FORM", payload: 0 });
      // console.log("error", err);
    }
  };


  return (
    <>
      <br />
      <CContainer>
        <h3>ITF-14 Review</h3>
        <hr />
        <CRow>
          <CCol>
            <p>
              <b>ITF-14 TYPE:</b> {selectedtype}
            </p>
          </CCol>


        </CRow>
        {/* Batch 2 information */}
        <CRow>
          <CCol>
            <p>
              <b>Selected Item(s):</b>
            </p>
            <ul>
              {isArray(itfinfo) ? itfinfo.map((i) => (
                <li>{i.label}</li>
              )) : itfinfo.label}</ul>
          </CCol>

        </CRow>
        <CRow>
          <CCol>
            <p>
              <b>Content Count:</b> {itemcount}
            </p>
          </CCol>
        </CRow>
        {/* <CRow>
          <CCol>
            <p>
              <b>Level:</b> {level.value}
            </p>
          </CCol>
        </CRow> */}


        <CButton
          onClick={() => dispatch({ type: "MOVE_TO_REVIEW", payload: 0 })}
          shape="rounded-pill"
        >
          <i className=" fa fa-arrow-left " /> Previous page
        </CButton>

        <CButton
          onClick={saveITF14Details}
          shape="rounded-pill"
          style={{ marginLeft: "10px" }}
        >
          {/* {postProduct && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )} */}
          Save request
        </CButton>
      </CContainer>
    </>
  );
};

export default ITF14Review;
